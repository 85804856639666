import React from 'react';
import { GiCarWheel } from "react-icons/gi";
import CarPattern from '../../UI/Pattern/Carpattern';

import './Spinner.css'; // Import a CSS file to style the spinner

function Spinner() {
  return (

        <div className="full-page-spinner">
            
    <CarPattern />
      <p className="lrotate360 spin center">
      <GiCarWheel className="icon-img" style={{ width: "3rem", height: "3rem" }} />
      </p>
    </div>
  );
}

export default Spinner;
