import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SlSupport } from "react-icons/sl";
import { LiaUserCheckSolid } from "react-icons/lia";
import { BsSend } from "react-icons/bs";
import { GiCartwheel } from "react-icons/gi";

import gras from '../../images/grass.svg';

function Footer() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 550);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="footer center">
            <div className={`icon-container ${isMobile ? 'pb-8' : 'pb-16'}`} style={{ backgroundImage: `url(${gras})` }}>
           

                <NavLink to="/support">
                    <div className="icon">
                        <SlSupport className="icon-img" />
                        <div className="icon-text">Hjälp</div>
                    </div>
                </NavLink>
              
                <NavLink to="/contact">
                    <div className="icon">
                        <BsSend className="icon-img" />
                        <div className="icon-text">Kontakta oss</div>
                    </div>
                </NavLink>
{isMobile? ''
:
   <span className="icon pt-16">
   <NavLink
       style={{
           color: '#4B017D',
           fontSize: 20,
           fontWeight: 100,
           textDecoration: 'none',
           fontFamily: 'Luckiest Guy',
       }}
       to="/"
       id="logo"
   >
       LET'S ROLL
   </NavLink>

   <p className='icon-logo-text'>© Copyright {new Date().getFullYear()} Let's Roll.</p>
</span>
}
             

                <NavLink to="/about">
                    <div className="icon">
                        <GiCartwheel className="icon-img" />
                        <div className="icon-text">Om oss</div>
                    </div>
                </NavLink>

                <NavLink to="/terms">
                    <div className="icon">
                        <LiaUserCheckSolid className="icon-img" />
                        <div className="icon-text">Villkor</div>
                    </div>
                </NavLink>
            </div>
        </div>
    );
}

export default Footer;
