import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from "../../index";
import { toast } from 'react-toastify';
import { AiOutlineEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { SlLock } from "react-icons/sl";

import './style.css'
import { newpassT } from '../../services/TeacherService';

function MydataPageT() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setValue,
    getValues
  } = useForm({
    mode: 'onChange', // Enable onChange mode to validate on every input change
  });

  const navigate = useNavigate();
  const { teacherstore } = useContext(Context);

  // Custom validation to check if the second password matches the first one
  const validatePasswordMatch = (value) => {
    const firstPassword = getValues('password'); // Get the value of the first password input
    return value === firstPassword || 'Lösenorden matchar inte.';
  };

const onSubmit = async (data) => {
  try {
    // You can proceed with the API call or additional logic if the form is valid
    if (isValid) {
      console.log('Calling newpass function');
      const teacherId = teacherstore.teacher.id
      const response = await newpassT(data.password, teacherId);
      console.log(response);
      navigate('/settings');
      toast.success('Lösenord har nu ändrats.');
    }
  } catch (error) {
    console.error(error);
  }
};

const [showPassword, setShowPassword] = useState(false);
const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};


  return (
    <div style={{maxWidth: "500px", margin: "0 auto"}}>

<div className="card2 mt-128 mb-128 ml-8 mr-8">
      
      
      <SlLock style={{ width: '30px', height: '30px',  color: "#54319f" }} />
      
      
      <h2 className="center mt-32 mb-32">Här byter du ditt lösenord</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
      <label>Skriv ditt nya lösenord nedan, minst 8 tecken</label>

        <div  className='password-input-container pl-8 pr-8'>
          <input
            className="mt-8"
            name="password"
            type={showPassword ? 'text' : 'password'}
            {...register('password',  { required: 'Vänligen skriv ditt lösenord',     minLength: {
      value: 8,
      message: 'Lösenordet måste vara minst 8 tecken långt',
    }, })}
          />
          
              <span onClick={togglePasswordVisibility} className='eye'>
                            {showPassword ? (
                                <AiFillEyeInvisible style={{ color: "#54319f", width: '30px', height: '30px', }} />) : (
                                <AiOutlineEye style={{ color: "#54319f", width: '30px', height: '30px', }} />)}
                        </span>
        </div>
        <p className="error">{errors.password && errors.password.message}</p>
       
       
       
        <label className="m-8">Upprepa nya lösenordet</label>
        <div className="password-input-container pl-8 pr-8" >
          <input
            className="mt-8"
            type={showPassword ? 'text' : 'password'}
            name="passwordRepeat"
            onChange={(e) => setValue('passwordRepeat', e.target.value)}
            {...register('passwordRepeat', {
             
              required: 'Vänligen upprepa ditt lösenord',
              validate: validatePasswordMatch, // Use the custom validation function
            })}
          />
                <span onClick={togglePasswordVisibility} className='eye'>
                            {showPassword ? (
                                <AiFillEyeInvisible style={{ color: "#54319f", width: '30px', height: '30px', }} />) : (
                                <AiOutlineEye style={{ color: "#54319f", width: '30px', height: '30px', }} />)}
                        </span>
        </div>
        <p className="error">{errors.passwordRepeat && errors.passwordRepeat.message}</p>

        <button
        className="button mt-16 pl-8 pr-8"
        style={{maxWidth: 350}}
        disabled={isSubmitting}
        onClick={handleSubmit}
>
  Skapa
</button>
      </form>
    </div>
    </div>
  );
}

export default MydataPageT;
