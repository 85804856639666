import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import RecoverPage from './pages/RecoverPage/RecoverPage';
import ChangePassword from './pages/RecoverPage/ChangePassword';
import Confirm from './pages/RecoverPage/Confirm';
import Logo from './UI/HamburgerMenu/Logo';
import LoginPage from './pages/LoginPage/LoginPage';
import PrivateRoutes from './utils/PrivateRoutes';
import ListTodos from './pages/MyDrivingNotesPage/DrivingNotes';
import AddTeacher from './pages/AddTeacher/AddTeacher';
import TeacherLoginPage from './pages/TeacherLogin/TeacherLoginPage';
import RecoverPageTeacher from './pages/RecoverPage/RecoverPageTeacher';
import ChangePasswordTeacher from './pages/RecoverPage/ChangePasswordTeacher';
import RegisterMain from './pages/RegisterPage/RegisterMain';
import RegisterStudent from './pages/RegisterPage/RegisterStudent/RegisterStudent';
import RegisterInstructor from './pages/RegisterPage/RegisterInstructor/RegisterInstructor';
import Footer from './UI/Footer/Footer';
import TeacherNotes from './pages/TeacherNotes/TeacherNotes';
import StartPage from './pages/StartPage/StartPage';
import ContactForm from './pages/ContactPage/ContactPage';
import SupportPage from './pages/SupportPage/SupportPage';
import About from './UI/Footer/About';
import Terms from './UI/Footer/Terms';
import CarPattern from './UI/Pattern/Carpattern';
// import PremiumPage from './pages/PremiumPage/PremiumPage';
// import CheckOutPage from './pages/PremiumPage/Strp/CheckOutPage';
// import CompletePage from './pages/PremiumPage/CompletePage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import MydataPage from './pages/SettingsPage/MydataPage';
import NotFoundPage from './pages/404/404';
import MyDetails from './pages/SettingsPage/MyDetailsPage';
import useGlobalInactivity from './utils/globalInactivity'

import {Context} from "./index";
import SchoolList from './pages/Schools/SchoolList';
import SettingsPageT from './pages/SettingsPageTeacher/SettingsPageT';
import MydataPageT from './pages/SettingsPageTeacher/MydataPageT';
import MyDetailsPageT from './pages/SettingsPageTeacher/MyDetailsPageT';
import Deals from './pages/DealsPage/deals';
import TeacherNotesItem from './pages/TeacherNotes/TeacherNotesItem';
import PremiumPage2 from "./pages/PremiumPage/PremiumPage2";
import ConfirmActivation from "./pages/ConfirmActivation/ConfirmActivation";
import Confirmrt from "./pages/RegisterPage/RegisterInstructor/Confirmrt";


const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };



const App = () => {

  const navigate = useNavigate();
  const { store, teacherstore } = useContext(Context);
  const { pathname } = useLocation();

    const inactivityTimeout = 80 * 60 * 1000; // 5 minute


  const isPrivateRoute = pathname.startsWith('/settings') ||
  pathname.startsWith('/notes') ||
  pathname.startsWith('/instructornotes') ||
  pathname.startsWith('/levelup') ||
  pathname.startsWith('/instructor') ||
  pathname.startsWith('/login') ||
  pathname.startsWith('/getpremium') ||
  pathname.startsWith('/mypass') ||
  pathname.startsWith('/mydetails') ||
  pathname.startsWith('/schools') ||
  pathname.startsWith('/hotDeals')



  const navigateToLogin = () => {
      {  store.isAuth ? store.logout()   &&  navigate('/login') : teacherstore.logoutTeacher()  && navigate('/instructorLogIn')}
        toast.info('Du har blivint utloggad pga långt inaktivitet, vänligen logga in igen');
  };

  // Use the useGlobalInactivity hook with the isActive flag
  useGlobalInactivity(inactivityTimeout, navigateToLogin, isPrivateRoute);

        return (
    <div className='wrapper main'>
      <Logo />
      <CarPattern />
      <ScrollToTop />
      <Routes>
          <Route element={<PrivateRoutes />}>
          <Route element={<AddTeacher />} path="/instructor" exact />
          <Route element={<ListTodos />} path="/notes" />
          <Route element={<TeacherNotes />} path="/instructornotes" />
          <Route element={<SettingsPage />} path="/settings" />
          <Route element={<SettingsPageT />} path="/settingsT" />

          <Route element={<SchoolList />} path="/schools" />
          <Route element={<Deals />} path="/hotDeals"  />

         {/* //premium */}
          <Route element={<PremiumPage2 />} path="/levelup" />
          {/*<Route element={<CheckOutPage />} path="/getpremium" />*/}
          {/*<Route element={<CompletePage />} path="/done" />*/}
         {/* //settings */}
         <Route element={<MydataPage />} path="/mypass" />
          <Route element={<MyDetails />} path="/mydetails" />
         {/* //settingsTeacher */}
         <Route element={<MydataPageT />} path="/mypassT" />
          <Route element={<MyDetailsPageT />} path="/mydetailsT" />
          {/* //notes */}
          <Route path="/instructorNotesGet/:studentId" element={<TeacherNotesItem />} />

        </Route>


        <Route element={<StartPage />} path="/" />
        <Route element={<LoginPage />} path="/login" />
        <Route element={<TeacherLoginPage />} path="/instructorLogIn" />
          <Route element={<ConfirmActivation />} path="/aktivering"  />

          {/* //footer */}
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />

        {/* //login */}
        <Route path="/recover" element={<RecoverPage />} />
        <Route path="/recoverPassword" element={<RecoverPageTeacher />} />
        <Route path="/passwordReset1/:teacherId/:restoreToken" element={<ChangePasswordTeacher />} />
        <Route path="/passwordReset/:studentId/:restoreToken" element={<ChangePassword />} />
        <Route path="/requestMade" element={<Confirm />} />
        <Route path="*" element={<NotFoundPage />} />

        <Route path="/register" element={<RegisterMain />} />
        <Route path="/registerStudent" element={<RegisterStudent />} />
        <Route path="/registerInstructor" element={<RegisterInstructor />} />
        <Route path="/confirmrt" element={<Confirmrt />} />
      </Routes>

      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
        theme="light"
        autoClose={2500}
        transition={Flip}
      />

      <Footer />
    </div>
  );
};

export default observer(App);
