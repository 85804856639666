import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../style.css';
import { GiPartyPopper } from "react-icons/gi";

export default function ConfirmActivation() {
    const navigate = useNavigate();
    const [timerTime, setTimerTime] = useState(20);


    const handleNavigateHome = () => {
        window.location.reload()
        window.location.href = '/'
    };



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleNavigateHome()
            }, 20000);

        const intervalId = setInterval(() => {
            setTimerTime((prevTime) => prevTime - 1);
        }, 1000);

        // Clean up the timeout and interval
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [navigate]);

    return (
        <div className="center" style={{ maxWidth: 400, paddingTop: 200 }}>
            <GiPartyPopper size={64} className="rotate360" style={{ color: '#534382' }} />
            <h2 className="p-16">Grattis!</h2>
            <h3>Nu är ditt konto aktiverad!</h3>
            <h3 className="p-16">Omdirigerar till startsidan om {timerTime} sekunder</h3>
            <button className={'ghostbutton mt-32'} onClick={handleNavigateHome}>Till startsidan </button>
        </div>
    );
}
