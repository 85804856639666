import React, {useState} from 'react';
import style from './style.css';
import {  RiSearchLine } from 'react-icons/ri';

const InputSearch = ({ data, setFilteredData }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filteredData = data.filter((item) => {
      return item.question.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredData(filteredData);
  };
  
  return (
    <form className="inputWithButton">
      <input
        type="text"
        placeholder="Sök din fråga här"
        value={searchQuery}
        onChange={handleSearchInputChange}
      />
           {searchQuery === '' ? (
        <span>
          <RiSearchLine size={30} className="search-icon" style={{ color: "#A78BFA" }} />
        </span>
      ) : null}
    </form>
  );
};


  export default InputSearch


