import React, { useContext, useState, useEffect } from 'react';
import { useForm, Resolver } from "react-hook-form";
import { Context } from "../../index";
import '../../style.css';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

type FormValues = {
    email: string;
};
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const resolver: Resolver<FormValues> = async (values) => {
    return {
        values: !values.email || !emailRegex.test(values.email) ? {} : values,
        errors: !values.email
            ? {
                email: {
                    type: "required",
                    message: "Kan inte vara tom!",
                }
            }
            : !emailRegex.test(values.email)
                ? {
                    email: {
                        type: "pattern",
                        message: "Ogiltig e-postadress",
                    }
                }
                : {}
    };
};

export default function RecoverPage() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>({
        resolver: resolver
    });
    const { store } = useContext(Context);
    const [email, setEmail] = useState<string>('');

    const navigate = useNavigate();
    const handleClick = () => navigate('/requestMade');

    const [clickCount, setClickCount] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number>(0);

    const onSubmit = handleSubmit((formData) => {
        if (formData.email.length >= 5) {
            store.recover(formData.email)
                .then(res => {
                    toast.success(`Om denna e-postadress är registrerad, får du återställningsinstruktioner.`);
                    handleClick();
                })
                .catch(error => toast.error('Något gick fel, försök igen.'));
        } else {
            toast.error('E-postadressen måste vara minst 5 tecken lång.');
        }
    });

    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [countdown]);

    const handleButtonClick = () => {
        if (!disabled) {
            setClickCount(prevCount => prevCount + 1);

            if (clickCount + 1 >= 6) {
                setDisabled(true);
                setCountdown(20);
                setTimeout(() => {
                    setDisabled(false);
                    setClickCount(0);
                }, 20000);
            }
        }
    };

    return (
        <div className='center pt-32' style={{ maxWidth: 350 }}>
            <h3>Återställningen av lösenordet för elever</h3>
            <p className='p-32'>Vänligen skriv ditt registrerade e-mail nedan</p>
            <form onSubmit={onSubmit} >
                <div>
                    <input {...register("email", {
                        required: 'Vänligen ange din e-postadress',
                        minLength: {
                            value: 5,
                            message: 'E-postadressen måste vara minst 5 tecken lång.'
                        },
                        pattern: {
                            value: emailRegex,
                            message: 'Ogiltig e-postadress'
                        }
                    })}
                           onChange={e => setEmail(e.target.value)}
                           placeholder="Skriv din e-mail här" />
                </div>

                {errors?.email && <p className='description error'>{errors.email.message}</p>}

                <button className='button' onClick={handleButtonClick} disabled={disabled}>
                    {disabled ? `Vänta ${countdown} sekunder` : 'Skicka'}
                </button>

            </form>
        </div>
    );
}
