import React from 'react';
import { GiCartwheel } from "react-icons/gi";
import wheel from '../../images/logo.png';

const About = () => {
  return (
      <div className="center">
        <img
            src="https://i.pinimg.com/originals/65/5d/2d/655d2da400a648abca600766a89deead.gif"
            width="300px"
            style={{ borderRadius: "10px" }}
            className="center mt-64"
            alt="About Let's roll"
        />

        <div style={{ maxWidth: "600px", margin: "0 auto" }}>
          <div className="icon"></div>
          <h2 className="pt-64" style={{ color: "#54319f" }}>
            <img src={wheel} style={{ color: "#54319f", width: "40px", height: "40px", marginBottom: "15px" }} />
            <br />
            Vilka är vi?
          </h2>
          <p className="left p-16" style={{ textAlign: "left" }}>
            Välkommen till Let's Roll! Vi är din schysta kompis när det gäller privat övningskörning. På Let's Roll brinner vi för att stötta och vägleda elever genom varje steg av deras körutbildning och hjälpa dem att bli självsäkra och säkra förare.
          </p>

          <h2 style={{ color: "#54319f" }}>
            Vår Mission
          </h2>
          <p className="left p-16" style={{ textAlign: "left" }}>
            Hos Let's Roll vill vi ge dig grymt bra stöd när du övningskör privat. Vi vill att varje elev ska ha en givande och lärorik upplevelse. Vår mission är att skapa en trygg och stödjande miljö där du kan utveckla dina körkunskaper och bygga en stabil grund för säker och ansvarsfull körning.
          </p>

          <h2 style={{ marginTop: "4vh", color: "#54319f" }}>
            Våra Tjänster
          </h2>
          <p className="left p-16" style={{ textAlign: "left" }}>
            <i>Övningskörkort:</i> Vi erbjuder schysta övningskörkort för dig och din handledare, vilket ger dig chansen att utveckla dina färdigheter i verkliga förhållanden.
            <br />
            <i>Kommande Erbjudanden:</i> Håll ögonen öppna för spännande verktyg och lösningar som kommer att hjälpa dig att nå ditt mål - att ta körkort.
          </p>
        </div>
      </div>
  );
};

export default About;
