import React from 'react';
import { useNavigate } from "react-router-dom";
import { BsRocketTakeoff } from "react-icons/bs";
import '../../style.css'; // Make sure to import your styles

const PremiumPage = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div className='center pt-64 pb-64'>

            <h1 className='center pb-32'>Grattis!</h1>
            <BsRocketTakeoff className="rocket-icon" />

            <h3>Alla premiumfunktioner är nu tillgängliga för dig!</h3>

            <div className='center p-16 pb-64' style={{ maxWidth: "500px" }}>
                <p>Just nu är alla premiumfunktioner gratis! Njut av och utforska alla våra verktyg som vi har gjort bara för dig.</p>
                <p className='pt-16'>Tack för att du är en del av vår gemenskap.</p>
                <p className=''>Vi hoppas att du älskar vår premiumupplevelse!</p>

                <button className='ghostbutton mt-32' onClick={handleBackClick}>
                    Gå tillbaka
                </button>
            </div>
        </div>
    );
}

export default PremiumPage;
