import React, { useState, ChangeEvent, KeyboardEvent, FC } from 'react';

interface AutocompleteProps {
    suggestions: string[];
    onSelect: (selectedSuggestion: string) => void;
    value: string;
    onChange: (value: string) => void;
}

const Autocomplete: FC<AutocompleteProps> = ({ suggestions, onSelect, value, onChange }) => {
    const [state, setState] = useState({
        activeSuggestion: 0,
        filteredSuggestions: [] as string[],
        showSuggestions: false,
        userInput: value,
    });

    const { activeSuggestion, filteredSuggestions, showSuggestions, userInput } = state;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const filtered = suggestions.filter((suggestion) =>
            suggestion.toLowerCase().startsWith(inputValue.toLowerCase())
        );

        setState({
            activeSuggestion: 0,
            filteredSuggestions: filtered,
            showSuggestions: true,
            userInput: inputValue
        });
        onChange(inputValue); // Notify parent of input change
    };

    const handleSelect = (selectedSuggestion: string) => {
        if (selectedSuggestion.trim() === '') {
            // Ignore empty suggestions
            return;
        }

        setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: selectedSuggestion
        });
        onSelect(selectedSuggestion);
    };


    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const { keyCode } = event;

        if (keyCode === 13) {
            // Enter key
            if (filteredSuggestions.length > 0) {
                handleSelect(filteredSuggestions[activeSuggestion]);
            }
        } else if (keyCode === 38 && activeSuggestion > 0) {
            // Up arrow
            setState({ ...state, activeSuggestion: activeSuggestion - 1 });
        } else if (keyCode === 40 && activeSuggestion < filteredSuggestions.length - 1) {
            // Down arrow
            setState({ ...state, activeSuggestion: activeSuggestion + 1 });
        }
    };

    const suggestionsListComponent = showSuggestions && userInput && (
        <div className="suggestions menu card2">
            {filteredSuggestions.length ? (
                filteredSuggestions.map((suggestion, index) => (
                    <p
                        key={suggestion}
                        className={index === activeSuggestion ? 'suggestion-active pb-8' : 'pb-8'}
                        onClick={() => handleSelect(suggestion)}
                    >
                        {suggestion}
                    </p>
                ))
            ) : (
                <div className="no-suggestions">
                    <em className='error'>Hittar inga, vänligen kontrollera stavningen</em>
                </div>
            )}
        </div>
    );

    return (
        <div className='mt-8'>
            <input
                type="text"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={userInput}
                placeholder="Skriv din kommun här"
            />
            {suggestionsListComponent}
        </div>
    );
};

export default Autocomplete;
