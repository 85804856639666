
import React, { useContext, useEffect, useState } from 'react';
import { useForm, Resolver } from "react-hook-form";
import { Context } from "../../index";
import '../../style.css';
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

type FormValues = {
    password: string;
};

export default function RecoverPage() {
    const { register,
        handleSubmit,
        formState: { errors } } = useForm<FormValues>();
    const { store } = useContext(Context);
    const [password, setPassword] = useState<string>('');
    const { restoreToken, studentId } = useParams<{ restoreToken: string, studentId: string }>();
    const navigate = useNavigate();
    const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

    useEffect(() => {
        if (restoreToken) {
            store.checkRest(restoreToken)
                .then(() => {
                    setIsTokenValid(true);
                })
                .catch(() => {
                    setIsTokenValid(false);
                });
        }
    }, [restoreToken, store]);

    const handleClick = () => {
        navigate('/');
        window.location.reload();
    }

    const onSubmit = handleSubmit(async () => {
        try {
            //@ts-ignore
            await store.changepass(password, studentId, restoreToken);
            toast.success('Lösenord är nu ändrat!');
            handleClick();
        } catch (e) {
            toast.error('An error occurred while changing the password.');
        }
    });

    if (isTokenValid === null) {
        return <div className={'center pt-64'}>Loading...</div>;
    }

    if (!isTokenValid) {
        // @ts-ignore
        return <div className={'center pt-64'}><h3>Inaktuell återställningslänk, vänligen försök igen genom att återställa lösenord på nytt</h3>
        <a onClick={() => navigate("/recover")} className={'pt-32'}>Återställ här</a>
    </div>;

    }

    return (
        <div className='center pt-32' style={{ maxWidth: 350 }}>
            <div className='center pb-32 pt-32'>
                <h3>Uppdatera ditt lösenord</h3>
                <h4 className='center pt-16'>Välj ditt lösenord, minst 8 tecken</h4>
            </div>

            <form onSubmit={onSubmit}>
                <div>
                    <input
                        {...register("password", {
                            required: "Vänligen skriv ditt lösenord, minst 8 tecken",
                            minLength: {
                                value: 8,
                                message: "Lösenord måste vara minst 8 tecken",
                            },
                        })}
                        type="password"
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Skriv ditt nya lösenord här"
                    />
                </div>
                {errors?.password && <p className='description error'>{errors.password.message}</p>}
                <button type="submit" className="button">Skapa nytt lösenord</button>
            </form>
        </div>
    );
}
