import axios, { AxiosResponse } from 'axios';

export default class AuthFreeService {

    static async sendMail(data: any, email: string): Promise<AxiosResponse> {
        try {
            const response = await axios.post( process.env.NODE_ENV === 'production' ? `https://letsroll.site/api/support` : `http://localhost:5000/api/support`, data, {
                withCredentials: true,
            });

            return response;
        } catch (error) {
            throw '';
        }
    }
}