import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../../index';
import Autocomplete from './UseAutoComplete';
import kommuner from '../kommuner.json';
import { Toggle } from "../../../UI/ToggleButton/Toggle";
import { Toggle4 } from "../../../UI/ToggleButton/Toggle4";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import './styles.css';

type FormValues = {
    name: string;
    email: string;
    password: string;
    city: string;
    category: string[];
};

// @ts-ignore
const PopUpMessage = ({ message, onClose }) => (
    <div className={'mb-32'}>
        <div className="popup2 center m-32 pb-48">
            <div className="popup-inner m-16 pt-64">
                <h3>{message}</h3>
                <button className={'ghostbutton mt-16'} onClick={onClose}>Jag förstår</button>
            </div>
        </div>
    </div>
);

export default function RegisterStudent() {
    const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors, watch } = useForm<FormValues>();
    const [password, setPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { store } = useContext(Context);
    const [showPassword, setShowPassword] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [categories, setCategories] = useState<string[]>([]);
    const [toggleState, setToggleState] = useState(false);
    const [toggle4State, setToggle4State] = useState(false);
    const [toggle5State, setToggle5State] = useState(false);

    const cityValue = watch('city');

    useEffect(() => {
        if (errors.name) toast.error(errors.name.message);
        if (errors.email) toast.error(errors.email.message);
        if (errors.password) toast.error(errors.password.message);
        if (errors.category) toast.error(errors.category.message);
        if (errors.city) toast.error(errors.city.message);
    }, [errors]);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleToggleChange = () => {
        const newToggleState = !toggleState;
        setToggleState(newToggleState);
        updateCategories('CategoryB', newToggleState);
    };

    const handleToggleChange4 = () => {
        const newToggle4State = !toggle4State;
        setToggle4State(newToggle4State);
        updateCategories('CategoryA', newToggle4State);
        if (newToggle4State) setShowPopUp(true);
    };

    const handleToggleChange5 = () => {
        const newToggle5State = !toggle5State;
        setToggle5State(newToggle5State);
        updateCategories('No category', newToggle5State);
    };

    const updateCategories = (category: string, add: boolean) => {
        const updatedCategories = add
            ? [...categories, category]
            : categories.filter((cat) => cat !== category);

        setCategories(updatedCategories);

        if (updatedCategories.length > 0) clearErrors('category');
    };

    const handleClick = () => navigate('/confirmrt');

    const onSubmit = (data: FormValues) => {
        const { name, email, password, city = '' } = data;

        // Check if city is valid by comparing it to the list of cities in kommuner.json
        if (!kommuner.includes(city)) {
            setError('city', { type: 'manual', message: 'Vänligen välj en giltig kommun från listan' });
            return;
        }

        if (categories.length === 0) {
            setError('category', { type: 'manual', message: 'Vänligen välj din kategori' });
            return;
        }

        setLoading(true);

        store.registration(name, email, password, city, categories)
            .then((response) => {
                setLoading(false);
                // @ts-ignore
                if (response.status === 200) {
                    toast.success(`Kontot är nu skapat`);
                    handleClick();
                } else {
                    // @ts-ignore
                    if (response.status === 400 || response.status === 500) {
                        toast.error("Something went wrong. Please try again.");
                    }
                }
            })
            .catch(() => {
                setLoading(false);
                toast.error("Registration failed. Please try again later.");
            });
    };

    return (
        <>
            <div className='center pt-32' style={{ maxWidth: 350 }}>
                <div className='center pb-32 pt-32'>
                    <h3 className='pt-32 p-16'>Student registrering</h3>
                    <h4 className='pt-32 p-16'>Steg 2/2</h4>
                </div>

                {showPopUp && (
                    <PopUpMessage
                        message="Tyvärr har vi ingen övningskort på det kategori just nu, men vi jobbar på det."
                        onClose={() => setShowPopUp(false)}
                    />
                )}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <label>Välj din körkortskategorin </label>
                    <div className='container center p-32'>
                        <Toggle toggle={toggleState} handleToggleChange={handleToggleChange} />
                        <Toggle4 toggle4={toggle4State} handleToggleChange4={handleToggleChange4} toggle5={toggle5State} handleToggleChange5={handleToggleChange5} />
                    </div>
                    {errors?.category && (
                        <p className='description error pt-32'>{errors.category.message}</p>
                    )}
                    <div>
                        <label>Skriv ditt namn</label>
                        <input
                            {...register('name', {
                                required: 'Vänligen skriv ditt namn',
                                minLength: {
                                    value: 2,
                                    message: 'Ditt namn kan inte vara kortare än två bokstäver',
                                },
                            })}
                            type='text'
                            onChange={(e) => setName(e.target.value)}
                            placeholder='Skriv ditt namn här'
                            className={"mt-8"}
                        />
                        {errors?.name &&
                            <p className='description error'>{errors.name.message}</p>
                        }

                        <label>Skriv din e-mail</label>
                        <input
                            {...register('email', {
                                required: 'Vänligen skriv ditt email',
                                minLength: {
                                    value: 5,
                                    message: 'Fältet kan inte vara tomt',
                                },
                            })}
                            type='email'
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Skriv ditt email här'
                            className={"mt-8"}
                        />
                        {errors?.email && <p className='description error'>{errors.email.message}</p>}

                        <label>Välj ett lösenord (minst 8 tecken)</label>
                        <div className='password-input-container'>
                            <input
                                {...register('password', {
                                    required: 'Vänligen skriv ditt lösenord, minst 8 tecken',
                                    minLength: {
                                        value: 8,
                                        message: 'Lösenord måste vara minst 8 tecken',
                                    },
                                })}
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='Välj ditt lösenord'
                                className={"mt-8"}
                            />
                            <span onClick={togglePasswordVisibility} className='eye'>
                                {showPassword ? (
                                    <AiFillEyeInvisible style={{ color: "#54319f", marginTop: '6px', width: '30px', height: '30px' }} />
                                ) : (
                                    <AiOutlineEye style={{ color: "#54319f", marginTop: '6px', width: '30px', height: '30px' }} />
                                )}
                            </span>
                        </div>
                        {errors?.password && <p className='description error'>{errors.password.message}</p>}

                        <label>Skriv din kommun och välj en i listan nedan </label>
                        <Autocomplete
                            suggestions={kommuner.map((city) => city)}
                            onSelect={(selectedCity) => {
                                setValue('city', selectedCity || '');
                                if (selectedCity.trim()) {
                                    clearErrors('city');
                                }
                            }}
                            value={cityValue || ''}
                            onChange={(value) => {
                                setValue('city', value || '');
                            }}
                        />

                        {errors?.city && <p className='description error'>{errors.city.message}</p>}
                    </div>
                    <button type='submit' className='button mt-32' disabled={loading}>
                        {loading ? 'Registrerar...' : 'Slutför registrering'}
                    </button>
                </form>
            </div>
        </>
    );
}