import React, { useState } from 'react';

const Autocomplete = ({ suggestions, onSelect, initialValue }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState(initialValue);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const handleChange = (e) => {
        const userInput = e.target.value;
        const filtered = suggestions.filter(
            (suggestion) =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setInputValue(userInput);
        setFilteredSuggestions(filtered);
        setShowSuggestions(true);
    };

    const handleClick = (suggestion) => {
        setInputValue(suggestion);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
        onSelect(suggestion);
    };

    return (
        <div>
            <input
                type="text"
                onChange={handleChange}
                value={inputValue}
            />
            {showSuggestions && inputValue && (
                <ul className="suggestions">
                    {filteredSuggestions.map((suggestion, index) => (
                        <p key={index} onClick={() => handleClick(suggestion)}>
                            {suggestion}
                        </p>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Autocomplete;
