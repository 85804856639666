// Importing necessary dependencies
import AxiosInstance, { AxiosResponse } from 'axios';
import $api from "../http";
import $api2 from "../http/index2";
import { AuthResponse } from "../pages/models/response/AuthResponse";
import {store, teacherstore} from "../index";
import NoteService from "./NoteService";
import {AuthResponse2} from "../pages/models/response/AuthResponse2";
import {toast} from "react-toastify";

interface Note {
    catA: string;
    catB: string;
    catC: string | null;
    catD: string | null;
    catE: string | null;
    catF: string | null;
    catG: string | null;
    catH: string | null;
    createdAt: string;
    groupId: string;
    id: string;
    isDone: boolean;
    number: number;
    nytitle: string;
    statA: string | null;
    statB: string | null;
    statC: string | null;
    statD: string | null;
    statE: string | null;
    statF: string | null;
    statG: string | null;
    statH: string | null;
    studentId: number;
    studentName: string;
    teacherId: number;
    updatedAt: string;
}
interface TeacherService {
    message: string;
    drivingNotes: Array<Note>;
}

// Defining the addTeacher function
// export const addTeacher = async (teacherEmail: string, studentId: string): Promise<AxiosResponse<AuthResponse>> => {
//     return $api.post('v1/addTeacher', { teacherEmail, studentId });
// };
//


// @ts-ignore
export const addTeacher = async  (teacherEmail: string, studentId: string):Promise<AxiosResponse<AuthResponse>>  => {
    try {
        // const teacherId = store.teacher.id;
        const response = $api.post('v1/addTeacher', { teacherEmail, studentId });
        return response;
    } catch (error) {
        throw '';
    }
}



//cehck if teacher have any notes

export const getNotes = async (teacherId: string):Promise<AxiosResponse<AuthResponse2>>  => {
    try {
        // const teacherId = store.teacher.id;
        const response = await $api2.get('/v1/teacher/getnotes',{ params: { teacherId } })
        return response;
    } catch (error) {
        throw '';
    }
}

///NotesTeacher///////////
class TeacherService {

    constructor(
        public teacherId: string,
        public isDone: boolean,
        public nytitle: string,
        public categories: any,
    ) {
    }


    static async getNotes(): Promise<AxiosResponse<TeacherService>> {
        try {
            const teacherId = teacherstore.teacher.id;
            const response = await $api2.get(`/v1/teacher/getnotes/${teacherId}`);
            return response;
        } catch (error) {
            throw '';
        }
    }


    static getTeacherNoteById = async (queryParams?: {
        studentId?: string;
        teacherId?: string
    }): Promise<AxiosResponse<NoteService[]>> => {
        try {
            const response = await $api2.get('/v1/teacher/notesGet', {params: queryParams});
            return response;
        } catch (error) {
            throw '';
        }
    }


    static updateTeacherNoteByIdT = async (
        id: string,  // Use string for UUID
        groupdId: string,
        nytitle: string,
        statA: string,
        statB: string,
        statC: string,
        statD: string,
        statE: string,
        statF: string,
        statG: string,
        statH: string,
        isDone: boolean
    ): Promise<AxiosResponse<NoteService>> => {
        const response = await $api2.put(`/v1/teacher/drivingnotes/${id}`, {
            id,
            groupdId,
            nytitle,
            statA,
            statB,
            statC,
            statD,
            statE,
            statF,
            statG,
            statH,
            isDone
        });
        return response;
    };

    static checkNotes = async (studentId: string): Promise<AxiosResponse<AuthResponse>> => {
        try {
            const response = await $api.get(`/v1/checkNote/${studentId}`);
            return response;
        } catch (error) {
            throw '';
        }
    };
    static delteach = async ( teacherId: string): Promise<AxiosResponse<AuthResponse>> => {
      return $api2.delete(`/v1/delTeach/${teacherId}`);
      }
}
export async function getdatatch(teacherId: string): Promise<AxiosResponse<AuthResponse>> {
    try {
      const response = await $api2.get(`v1/getTeacherData/${teacherId}`);
      return response;
    } catch (error) {
        throw '';
    }
  }


  export interface UserDataUpdate {
    name?: string;
    surname?: string;
    city?: string;
    pnr?: string; // Assuming pnr is a string, update the type accordingly
    phone?: string; // Assuming phonenumber is a string, update the type accordingly
    category?: 'A' | 'B'; // Assuming category can only be 'A' or 'B'
    autotransmission?: boolean;
  }

  export async function svdatausr(teacherId: string, data: UserDataUpdate): Promise<AxiosResponse<AuthResponse>> {
    try {
      const response = await $api2.put(`v1/updTeacherData/${teacherId}`, data);
      return response;
    } catch (error) {
        throw '';
    }
  }

export interface UserDataUpdate2 {
    autotransmission?: boolean;
  }

  export async function svdatausr2(teacherId: string, data: UserDataUpdate2): Promise<AxiosResponse<AuthResponse>> {
    try {
      const response = await $api2.put(`v1/updTeacherData2/${teacherId}`, data);
      return response; // Add this line to return the response
    } catch (error) {
        throw '';
    }
  }

  export async function newpassT(newPass: string, teacherId: string): Promise<AxiosResponse<AuthResponse>> {
    const data = { newPass: newPass };
    return $api2.put(`v1/setNewPteach/${teacherId}`, data);
  }

export default TeacherService;
