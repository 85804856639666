import React, { FC, useContext, useEffect, useState } from "react";
import { Context, teacherstore } from "../../index";
import { useParams } from "react-router-dom";
import DrivingNotesComponent from "./DrivingNotesComponent";
import TeacherService from "../../services/TeacherService";

interface TeacherNotesItemProps {
    data: {
        studentId: string;
        studentName: string;
        catA: string;
        catB: string;
        catC: string;
        // ... other properties
    };
    studentId: string;
}

interface OneCardType {
    id: any;
    groupId: string;
    number: number;
    studentName: string;
    // Add other properties as needed
}

const TeacherNotesItem: FC<TeacherNotesItemProps> = ({ data }) => {
    const { teacherstore } = useContext(Context);
    const [statusValues, setStatusValues] = useState(['🟣 Börjat', '🟠Visad kunskap', '🟢 Godkänd']);
    const [card, setCard] = useState<any>(null);

    const { studentId } = useParams();
    const teacherId = teacherstore.teacher.id;

    useEffect(() => {
        const fetchData = async () => {
            try {
                teacherstore.setLoading(true);
                const response = await teacherstore.gNotesid({ studentId, teacherId });
                setCard(response);
            } catch (error) {
            } finally {
                teacherstore.setLoading(false);
            }
        };

        fetchData();
    }, [studentId]);

    if (!card) {
        return null;
    }

    // Function to update a driving note
    const updateDrivingNote = async (card: any, field: any, value: any) => {
        const updatedNote = { ...card, [field]: value };
        // Send a PUT request to update the note
        try {
            await TeacherService.updateTeacherNoteByIdT(
                updatedNote.id,
                updatedNote.groupId,
                updatedNote.nytitle,
                updatedNote.statA,
                updatedNote.statB,
                updatedNote.statC,
                updatedNote.statD,
                updatedNote.statE,
                updatedNote.statF,
                updatedNote.statG,
                updatedNote.statH,
                updatedNote.isDone
            );
        } catch (error) {
            console.error('Error updating note: ', error);
        }
    };

    const handleStatusChange = (e: any, drivingNoteId: any, fieldName: any) => {
        const newStatus = e.target.value;
        // Update the local state
        setCard((prevCard: any) => {
            return {
                ...prevCard,
                drivingNotes: prevCard.drivingNotes.map((note: any) => {
                    if (note.id === drivingNoteId) {
                        return { ...note, [fieldName]: newStatus };
                    }
                    return note;
                }),
            };
        });
        // Update the backend data
        const cardToUpdate = card.drivingNotes.find((onecard: any) => onecard.id === drivingNoteId);
        updateDrivingNote(cardToUpdate, fieldName, newStatus);
    };

    const handleClick = (drivingNoteId: any) => {
        setCard((prevCard: any) => {
            return {
                ...prevCard,
                drivingNotes: prevCard.drivingNotes.map((onecard: any) => {
                    if (onecard.id === drivingNoteId) {
                        return {
                            ...onecard,
                            isDone: !onecard.isDone,
                        };
                    }
                    return onecard;
                }),
            };
        });
        const cardToUpdate = card.drivingNotes.find((onecard: any) => onecard.id === drivingNoteId);
        updateDrivingNote(cardToUpdate, 'isDone', !cardToUpdate.isDone);
    };

    // Use a counter to assign a number to each unique groupId
    let groupIdCounter = 0;
    const groupIdNumbers: { [key: string]: number } = {};

    return (
        <div className="mt-64 jcc center">
            {card.drivingNotes
                .slice()
                .sort((a: OneCardType, b: OneCardType) => {
                    // First, sort by groupId
                    const groupIdComparison = a.groupId.localeCompare(b.groupId);
                    if (groupIdComparison !== 0) {
                        return groupIdComparison;
                    }

                    // If groupId is the same, sort by number
                    return a.number - b.number;
                })
                .map((onecard: OneCardType) => {
                    // Print the group number only once for each groupId
                    if (!groupIdNumbers[onecard.groupId]) {
                        groupIdCounter += 1;
                        groupIdNumbers[onecard.groupId] = groupIdCounter;

                        return (
                            <React.Fragment key={onecard.groupId} >
                                <section className={'center pt-64'}>
                                    <p>{onecard.studentName}s kort</p>
                                    <p style={{ fontSize: "1rem", color: "#bebdbd" }}>
                                        nummer
                                    </p>
                                    <p style={{ fontSize: "4rem", color: "#bebdbd" }}>
                                        {groupIdNumbers[onecard.groupId]}
                                    </p>
                                </section>

                                <div className="ml-8 mr-8">
                                        <DrivingNotesComponent
                                            key={onecard.id}
                                            card={onecard}
                                            handleClick={handleClick}
                                            handleStatusChange={handleStatusChange}
                                            statusValues={statusValues}
                                        />
                                </div>
                            </React.Fragment>
                        );
                    }

                    return (


                        <div key={onecard.id} className={'ml-8 mr-8 '}>
                            <DrivingNotesComponent
                                card={onecard}
                                handleClick={handleClick}
                                handleStatusChange={handleStatusChange}
                                statusValues={statusValues}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default TeacherNotesItem;
