  import React, { useState, useEffect } from "react";
  import { useForm } from "react-hook-form";
  import AuthFreeService from "../../services/AuthFreeService";
  import { useNavigate } from "react-router-dom";
  import { BsSend } from "react-icons/bs";
  import { store, teacherstore } from "../../index";

  const ContactForm = () => {
    const navigate = useNavigate();
    const [timerTime, setTimerTime] = useState(20);
    const [messagesend, setMessagesend] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [selectError, setSelectError] = useState(false);
    const [loading, setLoading] = useState(false);

    const userEmail = store.user.email || teacherstore.teacher.email || '';

    useEffect(() => {
      if (messagesend) {
        const timeoutId = setTimeout(() => {
          navigate("/");
        }, 20000);

        const intervalId = setInterval(() => {
          setTimerTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => {
          clearTimeout(timeoutId);
          clearInterval(intervalId);
        };
      }
    }, [messagesend, navigate]);


    const onSubmit = async (data) => {
      if (!data.select) {
        setSelectError(true);
        return;
      } else {
        setSelectError(false);
      }
      setLoading(true);
      setMessagesend(false);

      // Ensure the email field is included in the data object
      const formData = { ...data, email: userEmail || data.email };

      try {
        await AuthFreeService.sendMail(formData);
        setMessagesend(true);
      } catch (error) {
        console.error("Error sending mail:", error);
      } finally {
        setLoading(false);
      }
    };


    const registerOptions = {
      name: { required: "Vänligen skriv ditt namn" },
      email: { required: "Vänligen skriv ditt e-mail" },
      select: { required: "Vänligen välj ett ämne" },
      message: {
        required: "Vänligen skriv ett meddelande",
        minLength: {
          value: 8,
          message: "Skriv ett meddelande på minst 8 tecken"
        }
      }
    };

    return (
        <>
          <div style={{ maxWidth: 500, margin: "0 auto" }}>
            <div className="card ml-8 mr-8 mt-128 center">
              <BsSend style={{ color: "#54319f", width: "35px", height: "35px", marginBottom: "10px", marginTop: "15px" }} />
              <h2 className="center mt-32 mb-32">Kontakta oss</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-32 pr-32">
                  <label>Ditt namn</label>
                  <input className="mt-8" placeholder="Skriv ditt namn här" name="name" type="text" {...register('name', registerOptions.name)} />
                  <p className="error">{errors?.name && errors.name.message}</p>
                </div>
                {!userEmail && (
                    <div className="pl-32 pr-32">
                      <label className="m-8">Email</label>
                      <input className="mt-8" placeholder="Skriv din e-mail här" type="email" name="email" {...register('email', registerOptions.email)} />
                      <p className="error">{errors?.email && errors.email.message}</p>
                    </div>
                )}
                <div className="pl-32 pr-32">
                  <label>Välj ett ämne</label>
                  <br/>
                  <select className="mt-8" name="select" {...register("select", registerOptions.select)}>
                    <option value="">Välj en kategori</option>
                    <option value="Teknisk support">Teknisk support</option>
                    <option value="Allmän fråga">Allmänt fråga</option>
                    <option value="Förslag">Förslag</option>
                    <option value="Övrigt">Övrigt</option>
                  </select>
                  <p className="error pt-8">{errors?.select && errors.select.message}</p>
                </div>
                <div className="pl-32 pr-32">
                  <label>Ditt meddelande</label>
                  <textarea className="mt-8" placeholder="Skriv ditt meddelande här" name="message" rows='8' {...register('message', registerOptions.message)} />
                  <p className="error pt-32">{errors?.message && errors.message.message}</p>
                </div>
                {messagesend && (
                    <div className="card center mt-16 p-8 pb-8 done" style={{ minWidth: "340px", maxWidth: "400px" }}>
                      <h3 className="p-16"> Thank you! </h3>
                      <p>Your message has been sent. We will look into the matter and get back to you with an answer within 24 hours</p>
                      <p className="p-16"> Redirect to startpage in {timerTime} seconds</p>
                    </div>
                )}
                <div className="pl-32 pr-32 pt-16 pb-32">
                  <button className="button" disabled={loading}>
                    {loading ? 'Skickar...' : 'Skicka'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
    );
  };

  export default ContactForm;
