import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../index';
import { controllesson, updatedeals } from '../../services/StudentService';
import { toast } from 'react-toastify';
import carGif from '../../../src/images/car.gif';

const SchoolList = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [isUpdating, setIsUpdating] = useState(false);
  const isMounted = useRef(true); // Track component mount status

  useEffect(() => {
    return () => {
      isMounted.current = false; // Set to false when the component unmounts
    };
  }, []);

  const handleButtonClick = async () => {
    setIsUpdating(true);

    try {
      await controllesson({ email: store.user.email });
      if (isMounted.current) {
        toast.success('Toppen! Vi hör av oss.');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error updating deals:', error);
      if (isMounted.current) {
        toast.error('Ett fel uppstod.');
      }
    } finally {
      if (isMounted.current) {
        setIsUpdating(false);
      }
    }
  };

  return (
      <div className='card center mt-64' style={{ maxWidth: 370, minHeight: 300 }}>
        <div className='m-16'>
          <h3 className='p-16 pb-32'>
            Vi jobbar på det! Snart har du en lista av exklusiva deals redo.
          </h3>
          <br />
          <img src={carGif} alt="Car animation" width="270" height="180" style={{ borderRadius: '9px' }} />
          <h3 className='p-16 pb-32'>
            Meddela dig när allt är klart?
          </h3>
          <button
              onClick={handleButtonClick}
              className='ghostbutton'
              disabled={isUpdating}
          >
            {isUpdating ? 'Uppdaterar...' : 'Yes!'}
          </button>
        </div>
      </div>
  );
};

export default SchoolList;
