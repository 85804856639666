import React, { useState, useEffect } from 'react';

import capa2 from '../../images/capa.jpg'

const CaptchaComponent = ({ onVerify }) => {
    const [num1, setNum1] = useState(0);
    const [num2, setNum2] = useState(0);
    const [operation, setOperation] = useState('+');
    const [answer, setAnswer] = useState('');
    const [isCorrect, setIsCorrect] = useState(false);

    const generateQuestion = () => {
        const randomNum1 = Math.floor(Math.random() * 10);
        const randomNum2 = Math.floor(Math.random() * 10);
        const isAddition = Math.random() < 0.5;
        setNum1(randomNum1);
        setNum2(randomNum2);
        setOperation(isAddition ? '+' : '-');
        setAnswer('');
        setIsCorrect(false);
    };

    useEffect(() => {
        generateQuestion();
    }, []);

    const handleAnswerChange = (e) => {
        setAnswer(e.target.value);
    };

    const verifyAnswer = () => {
        if (answer === '') {
            setIsCorrect(true);
            onVerify(true);
        } else {
            generateQuestion(); // Generate a new question if answer is incorrect
            onVerify(false); // Callback to parent component with verification result
        }
    };

    return (
        <div className={'FormGroup p-32 mb-32'}>
            <h3 className={'mb-8'}>För många misslyckade försök</h3>
            <p className={'mb-8'}>How much is {num1} {operation} {num2} = ?</p>
            <input className={'mb-8'} type="number" value={answer} onChange={handleAnswerChange} />
            <img className={'mb-8'} src={capa2} style={{width: '280px'}}/>

            <button className={'ghostbuttonC'} onClick={verifyAnswer}>Verify</button>
        </div>
    );
};

export default CaptchaComponent;
