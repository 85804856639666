// MyComponent.tsx
import React from 'react';
import {useNavigate} from "react-router-dom";

const RegisterMain: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className='center mt-64 p-16 ' style={{paddingBottom: "200px", maxWidth: "390px"}}>
        <div className='center flex' style={{ maxWidth: 350}}>
            <h2 className='pt-32 p-16'>Registrering av kontot på Let's Roll</h2>
        </div>
            <h4 className='pt-32 p-16'>Steg 1/2</h4>
            <div >
                <button onClick={() => navigate('/registerStudent')}  className='button'>
            Jag är en elev                </button>
            </div>
            <div>
                <button onClick={() => navigate('/registerInstructor')} className='button'>
            Jag är en handledare
                </button>
            </div>

        </div>
    );
};

export default RegisterMain;
