import axios from 'axios';
import { toast } from "react-toastify";

 // export const API_URL = `http://localhost:5000/api`;

export const API_URL = process.env.NODE_ENV === 'production' ? `https://letsroll.site/api` : `http://localhost:5000/api`;

// export const API_URL = process.env.NODE_ENV === 'production'
//     ? `https://letsroll.site/api`
//     : `http://192.168.0.191:5000/api`;

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export const handleUnauthorized = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('isAuthUser');
    sessionStorage.clear();
    setTimeout(() => {
        window.location.href = '/login';
    }, 3000); // 2 seconds timeout
};

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get(`${API_URL}/v1/refresh`, { withCredentials: true });
            sessionStorage.setItem('token', response.data.accessToken);
            return $api.request(originalRequest);
        } catch (e) {
            handleUnauthorized();
            toast.info('Du har blivit utloggad pga. lång inaktivitet, vänligen logga in igen.')
        }
    }
    throw error;
});

export default $api;
