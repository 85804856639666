import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes = () => {
    const authToken = sessionStorage.getItem('token');
    const authToken2 = sessionStorage.getItem('tokenteacher');
    return authToken || authToken2 ? <Outlet /> : <Navigate to="/login" />;
}


export default PrivateRoutes;

