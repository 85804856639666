
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../../index';
import TeacherService from '../../services/TeacherService';
import { toast } from 'react-toastify';

function SettingsPageT() {


  const { teacherstore } = useContext(Context);
  const navigate = useNavigate();
  const [isDeletePopupVisible, setDeletePopupVisible] = useState(false);

  const showDeletePopup = () => {
    setDeletePopupVisible(true);
  };

  const handleCancelClick = () => {
    setDeletePopupVisible(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      const teacherId = teacherstore.teacher.id;
      const response = await TeacherService.delteach(teacherId);
      // Handle success or show a message
      console.log('Account deleted successfully:', response);
      // Close the popup
      setDeletePopupVisible(false);
      teacherstore.setAuthT(false)
      // Optionally, navigate to a different page after deletion
      navigate('/');
      toast.success ('Ditt konto är nu raderad');
    } catch (error) {
      console.error('Error deleting account:', error);
      // Handle error or show an error message
    }
  };

  return (
    <div style={{ maxWidth: '500px', margin: '0 auto', marginTop: '120px' }}>
      <h2 className="center p-32">Dina konto inställningar</h2>
      <div style={{ margin: '0 auto', maxWidth: '350px' }}>
        <button onClick={() => navigate('/mypassT')} className="button">
          Byt lösenord
        </button>
        <button onClick={() => navigate('/mydetailsT')} className="button">
          Dina uppgifter
        </button>

        <div className="center pt-64">
          {/* Add a delete button that triggers the showDeletePopup function */}
          <button onClick={showDeletePopup} className="ghostbutton">
            Radera mitt konto
          </button>
        </div>

        {/* Delete confirmation popup */}
        {isDeletePopupVisible && (
          <div className="popup-overlay">
            <div className="popupwarn p-32" style={{ textAlign: "center"}}>
              <h2>Varning! Du håller på att radera ditt konto!</h2>
                <p className='pt-8'>All din data kommer att raderas permanent</p>
              <div className='mt-32 center' >
            <button onClick={handleDeleteConfirm} className="buttonRED">
               Jag samtycker, ta bort mitt konto
              </button>
              <button onClick={handleCancelClick} className="ghostbutton">
                Avbryt
              </button>
              </div>
         
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SettingsPageT;


