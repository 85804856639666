import React from 'react';
import { NavLink } from "react-router-dom";
import HamburgerMenu from './HamburgerMenu';



const Navigation = () => {
    return (
        <>
            <div className='center pt-32' >
                <NavLink
                    style={{ color: "#4B017D", fontSize: 30, fontWeight: 100, textDecoration: 'none', fontFamily: "Luckiest Guy" }}
                    to={'/'} > LET'S ROLL </NavLink>
            </div>
            <HamburgerMenu />
        </>
    )
}

export default Navigation