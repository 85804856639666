import React, { useState } from 'react';
import './ToggleButton.css';

function ToggleButton({ checked, onChange }) {
  const [on, setOnState] = useState(checked);

  const toggle = () => {
    const newState = !on;
    setOnState(newState);
    onChange(newState);
  };

  return (
    <div className='togglebutton center'>
      <div  onClick={toggle} className={on ? 'JA' : 'NEJ'}>
        {on ? 'JA' : 'NEJ'}
      </div>
    </div>
  );
}

export default ToggleButton;