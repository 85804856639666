import $api from '../http/index';
import { AxiosResponse } from "axios";
import { store } from "../index";
import $api2 from "../http/index2";



class NoteService {
    constructor(
        public id: number,
        public teacherId: string,
        public isDone: boolean,
        public nytitle: string,
        public categories: any,
    ) { }


    static createDrivingNote = async (newNote: any) => {
        try {
            return $api.post('/v1/drivingnotescreate', newNote);
        } catch (error) {
            throw ('message')
        }
    }


    static getAllDrivingNotes = async (): Promise<AxiosResponse<NoteService[]>> => {
        try {
            const studentId = store.user ? store.user.id : null;

            return $api.get('/v1/drivingnotes', {
                headers: {
                    'studentid': studentId
                },
                withCredentials: true // If your backend uses cookies for authentication
            });
        } catch (error) {
            throw ('message')
        }
    }



    static getDrivingNoteById = async (id: number): Promise<AxiosResponse<NoteService>> => {
        return $api.get(`/v1/drivingnotes/${id}`);
    }

    static updateDrivingNoteById = async (
        id: string,  // Use string for UUID
        nytitle: string,
        statA: string,
        statB: string,
        statC: string,
        statD: string,
        statE: string,
        statF: string,
        statG: string,
        statH: string,
        isDone: boolean
    ): Promise<AxiosResponse<NoteService>> => {
        const response = await $api.put(`/v1/drivingnotes/${id}`, {
            nytitle,
            statA,
            statB,
            statC,
            statD,
            statE,
            statF,
            statG,
            statH,
            isDone
        });
        return response;
    }

    static deleteDrivingNoteById = async (groupId: any): Promise<AxiosResponse<void>> => {
        return $api.delete(`/v1/drivingnotes/${groupId}`);
    }

    static checkNameT = async (studentId: string): Promise<AxiosResponse<{ teacherName: string }>> => {
        const response = await $api.get(`/v1/checkNameT`, { params: { studentId } });
        return response;
    }

    static delHand = async (teacherId: any, studentId: any): Promise<AxiosResponse> => {
        const response = await $api.delete('/v1/delOneHand', { params: { teacherId, studentId } });
        return response;
    }

    static getDescription = async (parameter: string, number: string) => {
        try {
            const response = await $api.post(`/v1/noteDescription`, { parameter, number });
            return response; // Return the entire response object
        } catch (error) {
            throw '';
        }
    };

    static getDescription2 = async (parameter: string, number: string): Promise<AxiosResponse> => {
        try {
            const response = await $api2.post(`/v1/noteDescriptionT`, { parameter, number });
            return response; // Return the entire response object
        } catch (error) {
            throw '';
        }
    }
}

export default NoteService;

