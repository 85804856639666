import { IUser } from "../pages/models/IUser";
import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import { toast } from "react-toastify";


export default class Store {
    user = {} as IUser;
    isAuth = false;
    isLoading = false;
    haveNote = false;
    lastActivityTime: number = 0;

    setLastActivityTime(currentTime: number) {
        this.lastActivityTime = currentTime;
    }

    constructor() {
        makeAutoObservable(this);
        this.loadSessionData();
    }


    setAuth(bool: boolean) {
        this.isAuth = bool;
        sessionStorage.setItem("isAuthUser", JSON.stringify(bool));
    }

    setUser(user: IUser) {
        this.user = user;
        sessionStorage.setItem("user", JSON.stringify(user));
    }



    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setHaveNote(value: boolean) {
        this.haveNote = value;
    }



    loadSessionData() {
        const storedUser = sessionStorage.getItem("user");
        const storedIsAuth = sessionStorage.getItem("isAuthUser");

        if (storedUser) {
            this.user = JSON.parse(storedUser);
        }
        if (storedIsAuth) {
            this.isAuth = JSON.parse(storedIsAuth);
        }
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password, { withCredentials: true });

            sessionStorage.setItem("token", response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            toast.success(`Välkommen ${response.data.user.name}`);
        } catch (error) {
            // Handle specific error responses
            // @ts-ignore
            if (error.response && error.response.status === 429) {
                // If 429 error, throw it to be caught in LoginForm
                throw error;
            } else {
                // Handle other errors (e.g., network error, server error)
                // @ts-ignore
                toast.error(error.response?.data?.message || "Login failed");
            }
        } finally {
            this.setLoading(false);
        }
    }

    async changepass(password: string, studentId: string, restoreToken: any) {
        try {
            const response = await AuthService.pass(password, studentId, restoreToken, { withCredentials: true });
            // @ts-ignore
            localStorage.setItem('restoreToken', response.data.restoreToken);
            window.location.reload();

        } catch (e) {
            // @ts-ignore
            toast.error(e.response?.data?.message)
        }
    }

    async checkRest(restoreToken: string) {
        try {
            const response = await AuthService.checkRest(restoreToken,{ withCredentials: true }) ;
            // Assuming the response indicates success or failure
            if (response.data.success) {
                return true;
            } else {
                throw new Error("Invalid or expired token");
            }
        } catch (e) {
            // @ts-ignore
            throw new Error(e.response?.data?.message || "Failed to validate token");
        }
    }


    async recover(email: string) {
        try {
            await AuthService.recover(email);
        } catch (e) {

        }
    }

    async registration(name: string, email: string, password: string, city: string, category: string[]) {
        try {
            const response = await AuthService.registration(name, email, password, city, category, { withCredentials: true });
            // @ts-ignore
            return { status: 200 };
        } catch (error) {

            // @ts-ignore
            toast.error(error.response?.data?.message);
        }
    }


    async logout() {
        try {
            const response = await AuthService.logout();
            this.setAuth(false);
            this.setUser({} as IUser);
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('isAuthUser');
            sessionStorage.clear();
            toast.success("Log out complete")
        } catch (e) {
            // @ts-ignore

            toast.error(e.response?.data?.message)
        }
    }

}



