export const q = [
  {
    "id": 1,
    "question": "Har abonnemanget någon bindningstid?",
    "answer": "Nej, vårt abonnemang har ingen bindningstid. Vi strävar efter tydlighet och transparens utan dolda kostnader. Just nu är det helt gratis att använa våra tjänster och fram till 1:a November."
  },
  {
    "id": 2,
    "question": "Vad gör ert abonnemang så straight forward?",
    "answer": "Vårt abonnemang är enkelt och lättförståeligt, utan komplicerade villkor. Vi fokuserar på att erbjuda dig en transparent upplevelse utan överraskningar."
  },
  {
    "id": 3,
    "question": "Hur kan jag dra nytta av ert abonnemang?",
    "answer": "Vår tjänst är utformad för att hjälpa dig som kund att uppnå professionella körkunskaper och strävar efter din framgång. Vi är här för att stödja dig på din resa mot körkortet."
  },
  {
    "id": 4,
    "question": "Hur fungerar supporten hos er?",
    "answer": "När vi har rullat ut våra tjänster fullt ut, kommer vi att erbjuda support inom 24 timmar. Vårt schysta supportteam är redo att svara på dina frågor och ge dig den hjälp du behöver, när du behöver det."

  },
  {
    "id": 5,
    "question": "Vilka funktioner ingår i ert abonnemang?",
    "answer": "För närvarande fokuserar vi på övningskörning, men vi arbetar ständigt med att utveckla mer spännande tjänster som kommer att stödja dig på ditt övningskörningsäventyr. Håll utkik!"
  },
  {
    "id": 6,
    "question": "Vad kan vi förvänta oss i framtiden för era tjänster?",
    "answer": "Vi är ständigt engagerade i att utveckla nya tjänster för att förbättra din övningskörningsupplevelse. Redan idag har vi några spännande tjänster på plats, och vi arbetar hårt för att introducera ännu fler funktioner som du kommer att älska. Håll ögonen öppna!"
  },
  {
    "id": 7,
    "question": "Kan jag överlåta mitt abonnemang till någon annan?",
    "answer": "Tyvärr är abonnemang är personlig och kan inte överlåtas till någon annan. Det är kopplat till ditt konto och användning."
  },
  {
    "id": 8,
    "question": "Hur skapar jag ett övningskörningskort med handledare?",
    "answer": "*Steg 1*: Skapa ett konto. *Steg 2*: Gå till 'Mitt övningskörningskort' och skapa ett nytt kort. *Steg 3*: Be din handledare att skapa ett konto. *Steg 4*: Lägg till din handledare genom att ange hans e-postadress under 'Meny - Lägg till handledare'. *Steg 5*: Din handledare loggar in och går till 'Meny - Elevens övningskort'."
  },  
  
  {
    "id": 9,
    "question": "Hur skapar jag ett övningskörningskort?",
    "answer": "*Steg 1*: Skapa ett konto. *Steg 2*: Gå till 'Mitt övningskörningskort' och skapa ett nytt kort. *Steg 3*: Klart'."
  },
  
  {
    "id": 10,
    "question": "Hur använder jag övningskortet?",
    "answer": "Du kör bilen med din handledare och markerar varje delkategori. Det finns tre statusar för varje delmoment: *Börjat*, *Visad kunskap* och *Godkänd*. När du är *klar* med ett moment markerar du eller din handledare att du är *klar* och ni går vidare till nästa moment. Det finns instruktioner för varje steg som beskriver och tydliggör hur man gör för att genomföra delmomentet."
  }
];

