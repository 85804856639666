 import {v1, v4} from 'uuid';

import './App.css';


const todosData = {
    groupId: v4(),
    items:
        [
               {
      id: v4(),
      isDone: false,
      nytitle: "Säkerhetskontroll",
      catA: "Inre säkerhetskontroll och spegel anpassning\n",
      catA1: ``,
      catB: "Yttre säkerhetskontroll",
      catB1: ``,
      number: 1,
    },


    {
        id: v4(),
        isDone: false,
        nytitle: "Förarplatsanpassning",
        catA: " Stolsjustering och bälte",
        catA1: ``,
        catB: "Under motorhuven / säkerhetskontroll",
        catB1: ``,
        catC: "Instrumentpanel och kontroller",
        catC1: ``,
        number: 2,
      },

            {
                id: v4(),
                isDone: false,
                nytitle: "Manuell växellåda",
                catA: "Introduktion",
                catA1: '',
                catB: "Fall 1: Acceleration",
                catB1: '',
                catC: "Fall 2: Uppväxling",
                catC1: '',
                catD: 'Fall 3: Nedväxling',
                catD1: '',
                catE: 'Fall 4: Bromsning',
                catE1: '',
                catF: 'Fall 5: Bakväxel',
                catF1: '',
                catG: 'Miljöaspekter och bränsleförbrukningen:\n',
                catG1: '',
                number: 3,


            },
            {
                id: v4(),
                isDone: false,
                nytitle: "Automat växellåda",
                catA: "Olika lägen i växellåda",
                catA1: '',
                number: 4,


            },

            {
                id: v4(),
                isDone: false,
                nytitle: "Inledande manövrering",
                catA: "Starta/stanna bilen(Manuell)",
                catA1: '',
                catB: "Börja köra bilen(Manuell)",
                catB1: '',
                catC: "Stanna bilen(Manuell)",
                catC1: '',
                catD: "Starta/stanna bilen(Automat)",
                catD1: '',
                catE: "Börja köra bilen(Automat)",
                catE1: '',
                catF: "Stanna bilen(Automat)",
                catF1: '',
                catG: 'Krypkörning',
                catG1: '',
                number: 5,

            },
            {
                id: v4(),
                isDone: false,
                nytitle: "Manövrering ",
                catA: "Backning",
                catA1: '',
                catB: " U-sväng",
                catB1: '',
                catC: "Saxvändning",
                catC1: '',
                catD: "Vända i trevägskorsning (T korsningen)",
                catD1: '',
                catE: "Vända i fyrvägskorsning",
                catE1: '',
                catF: "Parkering",
                catF1: '',
                catG: 'Fickparkering',
                catG1: '',
                number: 6,

            },


            {
                id: v4(),
                isDone: false,
                nytitle: "Samordning   Bromsning/Acceleration ",
                catA: "Samordning och motorik",
                catA1: '',
                catB:  "Avsökning och riskbedömning",
                catB1: '',
                catC: 'Acceleration av bilen',
                catC1: '',
                catD: 'Hård bromsning',
                catD1: '',
                number: 7,
            },



            {
                id: v4(),
                isDone: false,
                nytitle: "Mindre samhälle",
                catA: "Avsökning och riskbedömning",
                catA1: '',
                catB: "Hastighetsanpassning",
                catB1: '',
                catC: "Placering",
                catC1: '',
                catD: "Väjningsregler",
                catD1: ' ',
                number: 8,

            },
            {
                id: v4(),
                isDone: false,
                nytitle: "Mindre landsväg",
                catA: "Avsökning och riskbedömning",
                catA1: '',
                catB: "Hastighetsanpassning",
                catB1: '',
                catC: "Placering",
                catC1: '',
                catD: "Väjningsregler",
                catD1: '',
                catE: "Obevakad järnvägskorsning",
                catE1: '',
                catF: "Bevakad järnvägskorsning",
                catF1: '',
                number: 9,

            },

            {
                id: v4(),
                isDone: false,
                nytitle: "Stad",
                catA: "Avsökning och riskbedömning",
                catA1: '',
                catB: "Hastighetsanpassning",
                catB1: '',
                catC: "Placering",
                catC1: '',
                catD: "Väjningsregler",
                catD1: 'uiu',
                catF: "Enkelriktad trafik",
                catF1: 'iiu',
                catG: "Cirkulationsplats",
                catG1: 'iuuii',
                number: 10,

            },

            {
                id: v4(),
                isDone: false,
                nytitle: "Landsväg",
                catA: "Avsökning och riskbedömning",
                catA1: ',,,,,llll',
                catB: "Hastighetsanpassning",
                catB1: 'llll',
                catC: "Påfart/Placering",
                catC1: '',
                catD: "Avfart",
                catD1: 'kkkkk',
                catE: "Omkörning",
                catE1: 'kkkjjjj',
                number: 11,


            },
            {
                id: v4(),
                isDone: false,
                nytitle: "Motorväg och motortrafikled",
                catA: "Avsökning och riskbedömning",
                catA1: '',
                catB: "Påfart",
                catB1: '',
                catC: "Körning på Motorvägen",
                catC1: '',
                catD: "Avfart",
                catD1: '',
                catE: " Mitträckesväg ",
                catE1: '',
                number: 12,
            },
            {
                id: v4(),
                isDone: false,
                nytitle: "Mörker",
                catA: "Avsökning och riskbedömning / Möte",
                catA1: '',
                catB: "Användning av Helljus / Fotgängare / Hastighetsanpassning och position",
                catB1: '',
                catC: "Möte",
                catC1: '',
                catD: "Omkörning",
                catD1: '',
                catE: "Parkering",
                catE1: '',
                catF: "Nedsatt sikt",
                catF1: '',
                number: 13,

            },
            {
                id: v4(),
                isDone: false,
                nytitle: "Halt väglag",
                catA: "Typer av halka",
                catA1: '',
                catB: "Utrustning/Hjälpsystem Krypkörning",
                catB1: '',
                number: 14,

            },


            {
                id: v4(),
                isDone: false,
                nytitle: "Bilen och lutningar / parkering ",
                catA: "Motlut (stanna/köra)",
                catA1: '',
                catB: "Motlut (parkera)",
                catB1: '',
                catC: "Medlut (stanna/köra)",
                catC1: '',
                catD: "Medlut (parkera)",
                catD1: '',
                catE: "Backa och parkera runt hörnet",
                catE1: '',
                number: 15,


            },


            {
                id: v4(),
                isDone: false,
                nytitle: "Utbildningskontroll",
                catA: "Alla moment i stadskörning",
                catA1: '',
                catB: "Alla moment i landsvägskörning/motorväg",
                catB1: '',
                catC: " Parkering / Stadsparkering",
                catC1: '',
                catD: "Utbildningskontroll",
                catD1: '',
                number: 16,

            },


        ]
}

export default todosData