import React, { FC, useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiFillEyeInvisible } from 'react-icons/ai';
import CaptchaComponent from './CaptchaComponent';
import { toast } from 'react-toastify';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import { LiaRobotSolid } from "react-icons/lia";

const LoginForm: FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);
    const [loginAttempts, setLoginAttempts] = useState(0);
    const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [tooManyRequests, setTooManyRequests] = useState(false); // State to handle 429 error
    const navigate = useNavigate();
    const isMounted = useRef(true); // Use ref to track component mount state

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const { store } = useContext(Context);

    const handleLogin = async () => {
        try {
            setLoginAttempts((prev) => {
                const newAttempts = prev + 1;

                // Show CAPTCHA after 3rd attempt
                if (newAttempts >= 3 && !captchaVerified) {
                    setIsCaptchaVisible(true);
                }
                return newAttempts;
            });
            await store.login(email, password); // Call login service from Store
            if (isMounted.current) {
                setCaptchaVerified(false); // Reset CAPTCHA verification on successful login
                setTooManyRequests(false); // Reset too many requests state on successful login
            }
        } catch (error) {
            if (isMounted.current) {
                // Handle specific error responses
                // @ts-ignore
                if (error.response && error.response.status === 429) {
                    // If 429 error, set state to display error message
                    setTooManyRequests(true);
                } else {
                    // Handle other errors (e.g., network error, server error)
                    // @ts-ignore
                    toast.error(error.response?.data?.message || 'Login failed');
                }
            }
        } finally {
        }
    };

    const handleCaptchaVerification = (isHuman: boolean) => {
        if (isHuman) {
            setCaptchaVerified(true);
            setIsCaptchaVisible(false); // Hide CAPTCHA after successful verification
        } else {
            toast.error('CAPTCHA verification failed. Please try again.');
        }
    };

    // Reset login attempts and CAPTCHA state on component mount or successful login
    useEffect(() => {
        if (captchaVerified) {
            // Reset on successful verification
            setLoginAttempts(0);
            setIsCaptchaVisible(false);
            setCaptchaVerified(false);
        }
    }, [captchaVerified]);

    useEffect(() => {
        // Set the ref to true on mount
        isMounted.current = true;
        return () => {
            // Set the ref to false on unmount
            isMounted.current = false;
        };
    }, []);

    return (
        <div className='jcc center mt-64 mb-32' style={{ maxWidth: 400 }}>
            {tooManyRequests ? (
                <div className='error-message'>
                    <LiaRobotSolid style={{ height: "50px", width: "50px" }} />

                    <p>
                        Du är tillfälligt spärrat från att logga in, vänligen försök igen om 15 minuter eller klicka på länken nedan för att återställa ditt lösenord.
                    </p>
                    <button onClick={() => navigate('/recover')} className='ghostbutton jcc center mt-32 mb-32'>
                        Återställ lösenord
                    </button>
                </div>
            ) : (
                <>
                    <h2 className='pb-16'>Elevinloggningen</h2>
                    <input
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        minLength={5}
                        type='email'
                        placeholder='E-mail'
                    />
                    <div className='password-input-container'>
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            minLength={8}
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Lösenord'
                        />
                        <span onClick={togglePasswordVisibility} className='eye mb-16'>
                            {showPassword ? (
                                <AiFillEyeInvisible style={{ color: '#54319f', width: '30px', height: '30px' }} />
                            ) : (
                                <AiOutlineEye style={{ color: '#54319f', width: '30px', height: '30px' }} />
                            )}
                        </span>
                    </div>

                    {isCaptchaVisible && !captchaVerified && (
                        <CaptchaComponent onVerify={handleCaptchaVerification} />
                    )}
                    {!isCaptchaVisible || captchaVerified ? (
                        <button onClick={handleLogin} className='button center'>
                            Login
                        </button>
                    ) : null}
                    <button onClick={() => navigate('/register')} className='ghostbutton'>
                        Registrera ett konto
                    </button>
                    <br />
                    <button onClick={() => navigate("/instructorLogIn")} className='ghostbutton mt-32' style={{ maxWidth: 350 }}>
                        Logga in som handledare
                    </button>
                    <br />
                    <p>Glömt ditt lösenord? Återställ
                        <a onClick={() => navigate("/recover")}> här</a>
                    </p>
                </>
            )}
        </div>
    );
};

export default observer(LoginForm);
