import React, { FC } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// import './Popup.css'; // Add your styling for the popup

interface PopupProps {
  isVisible: boolean;
  onClose: () => void;
  onNavigate: () => void;
}

const Popup: FC<PopupProps> = ({ isVisible, onClose, onNavigate }) => {
  const navigate = useNavigate();

  return (
    <>
      {isVisible && (
        <div className='popup-overlay jcc card'>
          <div className='popup' style={{ opacity: 0.9999 }}>
            <h1 className="pb-16 pt-16">🔥</h1>
            <h2 className="pb-16">Bra jobbat!!</h2>
            <p> Nu närmar du dig målet. Vi rekommenderar starkt att testa dina körkunskaper på något proffsig trafikskola. Vi på Let´s roll har samlat en lista på skolor som kan testa dina färdigheter.</p>
            <div className="button-container">
              <button onClick={() => navigate("/schools")} className='button mt-32'>Ja tack!</button>
              <button className='ghostbutton' onClick={onClose}>Kanske senare</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
