  import React, { createContext, useEffect } from 'react';
  import ReactDOM from 'react-dom';
  import App from './App';
  import Store from './store/store';
  import Teacherstore from './store/teacherstore';
  import {BrowserRouter, HashRouter} from 'react-router-dom';
  // Define interfaces
  interface State {
    store: Store;
    teacherstore: Teacherstore;
  }
  // Create instances of Store and Teacherstore
  export const store = new Store();
  export const teacherstore = new Teacherstore();
  // Create context with both store and teacherstore
  export const Context = createContext<State>({
    store,
    teacherstore,
  });

  ReactDOM.render(
    <Context.Provider value={{ store, teacherstore }}>
      <BrowserRouter>
      {/*<HashRouter>*/}
        <App />
      {/*</HashRouter>*/}
      </BrowserRouter>
    </Context.Provider>,
    document.getElementById('root')
  );