import React, { useContext, useState } from 'react';
import { useForm, Resolver } from "react-hook-form";
import {Context, teacherstore} from "../../index";
import '../../style.css'
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

type FormValues = {
    email: string;
};

const resolver: Resolver<FormValues> = async (values) => {
    return {
        values: !values.email ? {} : values,
        errors: !values.email
            ? {
                email: {
                    type: "required",
                    message: "Can't be empty!",
                }
            }
            : {}
    };
};

export default function RecoverPageTeacher() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>({
        resolver: resolver
    });
    const { store } = useContext(Context);
    const [email, setEmail] = useState<string>('email')

    const navigate = useNavigate();
    const handleClick = () => navigate('/requestMade');


    const onSubmit = handleSubmit(() => (
        teacherstore.recoverTeacher(email)).then(res => toast.success(` Done !`)).then(handleClick))

    return (
        <div className='center pt-32' style={{ maxWidth: 350 }}>
            <h3>Återställningen av lösenordet för handledare</h3>
            <p className='p-32'>Vänligen skriv ditt registrerade e-mail nedan</p>
            <form onSubmit={onSubmit} >
                <div>
                    <input {...register("email")}
                        onChange={e => setEmail(e.target.value)}
                        minLength={5}
                           placeholder="Skriv din e-mail här" />
                </div>

                {errors?.email && <p className='description error'>{errors.email.message}</p>}

                <button  onClick={() => onSubmit} className='button'>
                    Skicka
                </button>

            </form>
        </div>
    );
}
