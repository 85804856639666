import React from 'react';
import { FaCar, FaBus, FaTruck, FaMotorcycle } from 'react-icons/fa';
import { RiTrafficLightFill } from "react-icons/ri";
import { PiTramLight } from "react-icons/pi";
import { IoCarOutline } from "react-icons/io5";
import { IoCarSportOutline } from "react-icons/io5";
import { IoBusOutline } from "react-icons/io5";
import { RiTaxiLine } from "react-icons/ri";

const CarPattern = () => {
  return (
    <div className="car-pattern-container">
      <div className="car-row">
        <FaCar className="car-icon" />
        <IoCarSportOutline className="car-icon" />
        <FaTruck className="car-icon" />
        <FaMotorcycle className="car-icon" />
        <IoBusOutline className="car-icon" />
        <IoBusOutline className="car-icon" />
        <RiTrafficLightFill className="car-icon" />
        <RiTaxiLine className="car-icon" />
        <FaTruck className="car-icon" />


      </div>
      <div className="car-row">
        <FaBus className="car-icon" />
        <FaTruck className="car-icon" />
        <FaMotorcycle className="car-icon" />
        <IoCarOutline className="car-icon" />
        <RiTrafficLightFill className="car-icon" />
        <PiTramLight className="car-icon" />
        <IoBusOutline className="car-icon" />
        <RiTaxiLine className="car-icon" />
        <FaBus className="car-icon" />

      </div>

      <div className="car-row">
      <IoBusOutline className="car-icon" />
      <RiTaxiLine className="car-icon" />
        <FaTruck className="car-icon" />
        <FaMotorcycle className="car-icon" />
        <IoCarOutline className="car-icon" />
        <FaBus className="car-icon" />
        <RiTrafficLightFill className="car-icon" />
        <PiTramLight className="car-icon" />
        <IoBusOutline className="car-icon" />


      </div>
      <div className="car-row">
        <FaMotorcycle className="car-icon" />
        <FaCar className="car-icon" />
        <FaBus className="car-icon" />
        <IoBusOutline className="car-icon" />
        <PiTramLight className="car-icon" />
        <PiTramLight className="car-icon" />

        <IoBusOutline className="car-icon" />
        <IoCarOutline className="car-icon" />
        <RiTrafficLightFill className="car-icon" />

      </div>
  
    </div>
  );
};

export default CarPattern;
