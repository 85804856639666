import { store } from "..";
import $api from "../http";
import { AuthResponse } from "../pages/models/response/AuthResponse";
import { AxiosResponse } from 'axios';

export const makePayment = async (amount: number, id: string): Promise<AxiosResponse<AuthResponse>> => {
    try {
        const response = await $api.post('/v1/getpay', { amount, id });
        // console.log('axios payment response', response);
        return response;
    } catch (error) {
        throw '';
    }
};

export const completeOrders = async (
    amount2: number,
    id: string,
    studentId: string
  ): Promise<AxiosResponse> => {
    try {
      const response = await $api.post("/v1/completeOrders", {
        amount2,
        id,
        studentId,
      });
      // console.log("axios completeOrders response", response);
      return response;
    } catch (error) {
        throw '';
    }

  };


  export const checkPremiumStatus = async (studentId: string): Promise<AxiosResponse<{ isUser: boolean }>> => {
    try {
      const response = await $api.get(`/v1/checkprm?id=${studentId}`);
      return response;
    } catch (error) {
        throw '';
    }
  };

