import React, { useState } from 'react';
import InputSearch from './InputSearch/InputSearch';
import './style.css';
import { q } from './Questions'; // Update the import statement
import { SlSupport } from "react-icons/sl";

const SupportPage = () => {
  const [filteredData, setFilteredData] = useState(q);

  return (
    <>
      <div className='pt-64 center'>
        {/* First Section */}

        <SlSupport style={{ color: "#54319f", width: "35px", height: "35px", marginBottom: "15px", marginTop: "15px" }}/>

        <h2 className='pb-64 center'> Hjälp, frågor och svar </h2>

        <section className="rows center">
          <div className="flex rows center">
            <InputSearch data={q} setFilteredData={setFilteredData} />
          </div>
        </section>

        {/* Second Section */}
        
        <div className="center container pt-32 ">
          {filteredData.map((item) => (
            <span key={item.id} className="row card" style={{ height: "auto", maxWidth: "350px" }}>
              <h2 className='p-8'>
                {item.question}
              </h2>  
              <br />
              <p className='p-16'>
                {item.answer.split('*').map((text, index) => (
                  index % 2 === 0 ? text : <strong key={index}>{text}</strong>
                ))}
              </p> 
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default SupportPage;
