
import React, { useState, useEffect } from 'react';
import NoteService from "../../services/NoteService";
import debounce from "lodash.debounce";
import AccordionT from "./Accordion/AccordionT";

function DrivingNotesComponent({ card, handleClick, statusValues, handleStatusChange }) {
    const [imageData, setImageData] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 868);

    const handleExpandClick = async (parameter) => {
        const { number } = card;

        try {
            const imageDataArray = [];
            for (let index = 1; index <= 3; index++) {
                const imageResponse = await NoteService.getDescription2(parameter, number.toString());
                const arrayBuffer = await imageResponse.data;
                const blob = new Blob([arrayBuffer]);
                imageDataArray.push(blob);
            }
            setImageData(imageDataArray);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    const InfoA1 = "Tala högt om dina handlingar för din handledare om du känner dig bekväm med att köra och prata samtidigt. På så sätt kan din handledare följa dina tankar och ha koll på dina handlingar och eventuella misstag. Denna teknik är ett stort plus för din inlärningsprocess.";
    const InfoA5 = "I det här momentet, läs igenom hela instruktionen innan du påbörjar övningarna. Välj en lugn gata, ett industriområde, en stor parkering eller någon annan lämplig plats utan bilar eller trafikanter.";
    const InfoA6 = "Välj en lugn gata, ett industriområde, en landsväg eller någon annan lämplig plats utan bilar eller trafikanter för dina övningar.";
    const InfoA7 = "Välj en lugn gata, ett industriområde, en landsväg eller någon annan lämplig plats utan bilar eller trafikanter för dina övningar.";
    const InfoA15 = "Välj en trafikfri plats (till exempel ett villaområde) där det finns gott om utrymme mellan bilarna du ska parkera mellan. Detta för att undvika kollisioner med parkerade bilar.";
    const InfoA16 = "Tala högt om dina handlingar för din handledare om du känner dig bekväm med att köra och prata samtidigt. På så sätt kan din handledare följa dina tankar och ha koll på dina handlingar och eventuella misstag.";

    useEffect(() => {
        const handleWindowSizeChangeDebounced = debounce(() => {
            setIsMobile(window.innerWidth <= 869);
        }, 200);

        window.addEventListener('resize', handleWindowSizeChangeDebounced);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChangeDebounced);
            handleWindowSizeChangeDebounced.cancel();
        };
    }, []);

    return (
        <>
            {card.number === 1 && <h1 className='center pt-32' style={{ color: "#bebdbd", width: "100%", textAlign: "center" }}>GRUNDLÄGGANDE MOMENT</h1>}
            {card.number === 8 && <h1 className='center pt-32' style={{ color: "#bebdbd", width: "100%", textAlign: "center" }}>INLEDANDE TRAFIK</h1>}
            {card.number === 10 && <h1 className='center pt-32' style={{ color: "#bebdbd", width: "100%", textAlign: "center" }}>STADSTRAFIK OCH LANDSVÄG</h1>}
            {card.number === 15 && <h1 className='center pt-32' style={{ color: "#bebdbd", width: "100%", textAlign: "center" }}>AVANCERADE MOMENT</h1>}

            <div
                key={card.id}
                style={{ backgroundColor: '#f8f7f8', borderRadius: '5px' }}
                className={
                    isMobile
                        ? card.isDone
                            ? 'is-done item p-8 mt-16 card'
                            : 'item p-8 mt-16 pl-8 pr-8 card'
                        : card.isDone
                            ? 'is-done item p-32 m-16 card'
                            : 'item p-32 m-16 card'
                }
            >
                {card.isDone &&<> <h2>🏁</h2><br />  <h2 className="pb-32">Klar! </h2></>}
                <div className='bg1 mb-16 p-8'>
                    {!card.isDone &&<> <h3>🏁</h3><br /> <h3 className="pb-8"> Klar med moment? Kryssa i nedan </h3></>}
                    <input className="mt-16 checkbox" type="checkbox" onChange={() => handleClick(card.id)} checked={card.isDone} />
                </div>
                <h2 className="pb-16">{card.nytitle}</h2>
                {card.number === 1 && InfoA1}
                {card.number === 5 && InfoA5}
                {card.number === 6 && InfoA6}
                {card.number === 7 && InfoA7}
                {card.number === 15 && InfoA15}
                {card.number === 16 && InfoA16}

                {card.catA && (
                    <>
                        <h3 className='p-16 pt-32'>{card.catA}</h3>
                        <AccordionT
                            title={<h3>Instruktioner</h3>}
                            parameter={`catA1`}
                            number={card.number.toString()}
                            onExpandClick={handleExpandClick}
                            imageData={imageData}
                        />
                        <select
                            value={card.statA || ''}
                            onChange={(e) => handleStatusChange(e, card.id, 'statA')}
                        >
                            <option value="">----Välj----</option>
                            {statusValues.map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        <hr className='center mt-8' style={{ color: "#bebdbd" }} />
                    </>
                )}
                {card.catB && (
                    <>
                        <h3 className='p-16 pt-32'>{card.catB}</h3>
                        <AccordionT
                            title={<h3>Instruktioner</h3>}
                            parameter={`catB1`}
                            number={card.number.toString()}
                            onExpandClick={handleExpandClick}
                            imageData={imageData}
                        />
                        <select
                            value={card.statB || ''}
                            onChange={(e) => handleStatusChange(e, card.id, 'statB')}
                        >
                            <option value="">----Välj----</option>
                            {statusValues.map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        <hr className='center mt-8' style={{ color: "#bebdbd" }} />
                    </>
                )}
                {card.catC && (
                    <>
                        <h3 className='p-16 pt-32'>{card.catC}</h3>
                        <AccordionT
                            title={<h3>Instruktioner</h3>}
                            parameter={`catC1`}
                            number={card.number.toString()}
                            onExpandClick={handleExpandClick}
                            imageData={imageData}
                        />
                        <select
                            value={card.statC || ''}
                            onChange={(e) => handleStatusChange(e, card.id, 'statC')}
                        >
                            <option value="">----Välj----</option>
                            {statusValues.map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        <hr className='center mt-8' style={{ color: "#bebdbd" }} />
                    </>
                )}
                {card.catD && (
                    <>
                        <h3 className='p-16 pt-32'>{card.catD}</h3>
                        <AccordionT
                            title={<h3>Instruktioner</h3>}
                            parameter={`catD1`}
                            number={card.number.toString()}
                            onExpandClick={handleExpandClick}
                            imageData={imageData}
                        />
                        <select
                            value={card.statD || ''}
                            onChange={(e) => handleStatusChange(e, card.id, 'statD')}
                        >
                            <option value="">----Välj----</option>
                            {statusValues.map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        <hr className='center mt-8' style={{ color: "#bebdbd" }} />
                    </>
                )}
                {card.catE && (
                    <>
                        <h3 className='p-16 pt-16'>{card.catE}</h3>
                        <AccordionT
                            title={<h3>Instruktioner</h3>}
                            parameter={`catE1`}
                            number={card.number.toString()}
                            onExpandClick={handleExpandClick}
                            imageData={imageData}
                        />
                        <select
                            value={card.statE || ''}
                            onChange={(e) => handleStatusChange(e, card.id, 'statE')}
                        >
                            <option value="">----Välj----</option>
                            {statusValues.map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        <hr className='center mt-8' style={{ color: "#bebdbd" }} />
                    </>
                )}
            </div>
        </>
    );
}

export default DrivingNotesComponent;
