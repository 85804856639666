import React from 'react';
import './toggle.css';

interface ToggleProps {
    toggle: boolean;
    handleToggleChange: () => void;
}

export const a = <span className='category'>B</span>;
export const b = <span className='category'>B</span>;

export const Toggle: React.FC<ToggleProps> = ({ toggle, handleToggleChange }) => {
    return (
        <div onClick={handleToggleChange}>
            <div className={`toggle-btn ${!toggle ? 'disable' : ''}`}>
                {toggle ? a : b}
            </div>
        </div>
    );
};