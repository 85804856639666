import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../style.css';
import { LiaAtSolid } from 'react-icons/lia';

export default function Confirm() {
    const navigate = useNavigate();
    const [timerTime, setTimerTime] = useState(30);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate('/');
        }, 30000);

        const intervalId = setInterval(() => {
            setTimerTime((prevTime) => prevTime - 1);
        }, 1000);

        // Clean up the timeout and interval
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [navigate]);

    return (
        <div className="center" style={{ maxWidth: 360, paddingTop: 200 }}>
            <LiaAtSolid size={64} className="rotate360" style={{ color: '#534382' }} />
            <h2 className="p-16">Done! </h2>

            <h3>
                Om den angivna e-postadressen är registrerad, får du ett e-postmeddelande med ytterligare instruktioner om hur du får ett nytt lösenord.
            </h3>

            <h3  className="p-16"> Omdirigera till startsidan {timerTime} seconds</h3>
        </div>
    );
}
