import React, { useState, useEffect } from 'react';
import NoteService from '../../../services/NoteService';
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";
import parse from 'html-react-parser'; // Import the parser

const AccordionT = ({ title, parameter, onExpandClick, id, number }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentWithImages, setContentWithImages] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 868);

    useEffect(() => {
        fetchData();
    }, [parameter, number]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
        onExpandClick(parameter, number);
    };

    const fetchData = async () => {
        try {
            const response = await NoteService.getDescription2(parameter, number);
            const paragraphs = response.data[parameter].split('\n');
            const imageUrls = response.data[`${parameter}img`] || [];
            const contentWithImages = paragraphs.map((paragraph, index) => ({
                paragraph: processText(paragraph),
                imageUrl: imageUrls[index] || null,
                paragraphNumber: index + 1
            }));
            setContentWithImages(contentWithImages);
        } catch (error) {
            console.error(`Error fetching data for ${parameter}${number}:`, error);
        }
    };

    const processText = (text) => {
        const stegRegex = /steg\s(1[0-9]|20|[1-9])/gi;
        const fallRegex = /fall\s(1[0-9]|20|[1-9])/gi;
        const automatRegex = /-AUTOMAT-/gi; // Updated to match the word "AUTOMAT" exactly
        const numberDotRegex = /(?:^|\s)(1[0-9]|20|[1-9])\./g;

        return text
            .replace(stegRegex, match => `<strong class="highlighted">${match}</strong>`)
            .replace(automatRegex, match => `<strong class="highlighted2">${match}</strong>`)
            .replace(fallRegex, match => `<strong class="highlighted">${match}</strong>`)
            .replace(numberDotRegex, match => `<strong class="highlighted">${match}</strong>`);
    };




    return (
        <>
            {isMobile ? (
                <div className="accordion-m center">
                    <div
                        className="accordion-header center2"
                        style={{ backgroundColor: '#f1ebff', padding: '10px', marginBottom: '-5px', borderRadius: '5px' }}
                        onClick={toggleAccordion}
                    >
                        <span>{title}</span>
                        {isOpen ? <MdOutlineExpandLess className="custom-icon" /> : <MdOutlineExpandMore className="custom-icon" />}
                    </div>
                    {isOpen && (
                        <div style={{ backgroundColor: '#f1ebff', padding: '9px', borderRadius: '5px' }}>
                            {contentWithImages.map(({ paragraph, imageUrl, paragraphNumber }) => (
                                <div key={paragraphNumber}>
                                    <p style={{ textAlign: 'left' }}>{parse(paragraph)}</p>
                                    {imageUrl && <img style={{ width: "320px", paddingTop: "8px", paddingBottom: "8px" }} src={imageUrl} alt={`img-${paragraphNumber}`} />}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className="accordion center">
                    <div
                        className="accordion-header"
                        style={{ backgroundColor: '#f1ebff', paddingTop: '10px', marginBottom: '-5px', borderRadius: '5px' }}
                        onClick={toggleAccordion}
                    >
                        <span>{title}</span>
                        {isOpen ? <MdOutlineExpandLess className="custom-icon" /> : <MdOutlineExpandMore className="custom-icon" />}
                    </div>
                    {isOpen && (
                        <div className="accordion-content" style={{ backgroundColor: '#f1ebff', padding: '15px', borderRadius: '5px' }}>
                            {contentWithImages.map(({ paragraph, imageUrl, paragraphNumber }) => (
                                <div key={paragraphNumber}>
                                    <p style={{ textAlign: 'left' }}>{parse(paragraph)}</p>
                                    {imageUrl && <img style={{ width: "400px", paddingTop: "8px", paddingBottom: "10px" }} src={imageUrl} alt={`img-${paragraphNumber}`} />}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default AccordionT;
