import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../style.css';
import { GiPartyPopper } from "react-icons/gi";

export default function Confirmrt() {
    const navigate = useNavigate();
    const [timerTime, setTimerTime] = useState(20);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate('/');
        }, 20000);

        const intervalId = setInterval(() => {
            setTimerTime((prevTime) => prevTime - 1);
        }, 1000);

        // Clean up the timeout and interval
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [navigate]);

    return (
        <div className="center pl-16 pr-16" style={{ maxWidth: 400, paddingTop: 200 }}>
            <GiPartyPopper size={64} className="rotate360" style={{ color: '#534382' }} />
            <h3>Nu är ditt konto skapad!</h3>
            <h3>Sista steget är att aktivera ditt konto, kika din e-post för aktiveringsmail.</h3>
            <h3 className="p-16">Omdirigerar till startsidan om {timerTime} sekunder</h3>
            <button className={'ghostbutton mt-32'} onClick={()=>  navigate('/')}>Till startsidan </button>
        </div>
    );
}
