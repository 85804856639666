import React, { useState, useEffect } from 'react';
import Popup from "./Popup.tsx";
import NoteService from '../../services/NoteService';
import Accordion from './Accordion/Accordion';
import debounce from "lodash.debounce";


function NoteItem({ note, handleClick, handleStatusChange, statusValues }) {
  const [imageData, setImageData] = useState([]);
  //popup window
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    const hasPopupBeenShown = localStorage.getItem('popupShown');

    if (!hasPopupBeenShown && ((note.isDone && note.number === 15) || (note.isDone && note.number === 16))) {
      setPopupVisible(true);
      localStorage.setItem('popupShown', 'true');
    } else {
      setPopupVisible(false);
    }
  };

  useEffect(() => {
    togglePopup();
  }, []);

  useEffect(() => {
    togglePopup();
  }, [note.isDone, note.number]);


  const handleExpandClick = async (parameter) => {
    const { number } = note;

    try {
      const imageDataArray = [];
      // Fetch all images for the note item
      for (let index = 1; index <= 3; index++) {
        const imageResponse = await NoteService.getDescription(parameter, number.toString());
        const arrayBuffer = await imageResponse.data;
        const blob = new Blob([arrayBuffer]);
        imageDataArray.push(blob);
      }
      setImageData(imageDataArray);
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  const InfoA5 = "I den här momentet läs igenom hela intruktionerna innan du påbörjar övningar. " +
      " Lämplig övningsplats lugn gata / industriområde eller en stor parkering eller annan lämplig plats utan några bilar eller trafikanter."
  const InfoA6 = "Lämplig övningsplats lugn gata / industriområde / landsväg  eller annan lämplig plats utan några bilar eller trafikanter."
  const InfoA7 = "Lämplig övningsplats lugn gata / industriområde / landsväg  eller annan lämplig plats utan några bilar eller trafikanter."
  const InfoA15 = "Välj en trafikfri plats (villaområde eller liknande) där det finns god marginal mellan bilarna du ska parkera mellan , detta är för att undvika kollistionen med parkerade bilar."

  const InfoA16 = "Tala om högt om dina handlingar för din handledare om du känner dig bekväm att köra och prata samtidigt. På så sätt kan din handledare följa dina tankar och ha koll på dina handlingar och eventuella misstag. "
  const InfoA1 = "Tala om högt om dina handlingar för din handledare om du känner dig bekväm att köra och prata samtidigt. På så sätt kan din handledare följa dina tankar och ha koll på dina handlingar och eventuella misstag om du inte känner dig bekväm med detta än träna på detta vid senare tillfällen. "


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 868);

  useEffect(() => {
    // Define a debounced version of the handleWindowSizeChange function
    const handleWindowSizeChangeDebounced = debounce(() => {
      setIsMobile(window.innerWidth <= 869);
    }, 200); // Adjust debounce delay as needed

    // Add event listener using the debounced function
    window.addEventListener('resize', handleWindowSizeChangeDebounced);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleWindowSizeChangeDebounced);
      handleWindowSizeChangeDebounced.cancel(); // Cancel the debounced function to avoid memory leaks
    };
  }, []);


  // @ts-ignore
  return (
      <>

          {note.number === 1 ?   <h1 className='center pt-32' style={{ color: "#bebdbd", width: "100%", textAlign: "center" }}> GRUNDLÄGGANDE MOMENT </h1> : ''}
          {note.number === 8 ?      <h1 className='center pt-32' style={{ color: "#bebdbd", width: "100%", textAlign: "center" }}> INLEDANDE TRAFIK </h1> : ''}
          {note.number === 10 ?        <h1 className='center pt-32' style={{ color: "#bebdbd", width: "100%", textAlign: "center" }}>STADSTRAFIK OCH LANDSVÄG </h1> : ''}
          {note.number === 15 ?       <h1 className='center pt-32' style={{ color: "#bebdbd", width: "100%", textAlign: "center" }}> AVANCERADE MOMENT</h1> : ''}

        <div
            key={note.id}
            style={{ backgroundColor: '#f8f7f8', borderRadius: '5px' }}
            className={
              isMobile
                  ? note.isDone
                      ? 'is-done item p-8 mt-16 card'
                      : 'item p-8 mt-16 card'
                  : note.isDone
                      ? 'is-done item p-32 m-16 card'
                      : 'item p-32 m-16 card'
            }
        >


          {note.isDone === true ?  <> <h1>🏁</h1> <h1>  Klar ! </h1>  </>: ''}
          <div className='bg1 mb-16 p-8'>
            {note.isDone === false ? <> <h2>🏁</h2> <h3 className="pt-8 pb-8">  Klar med moment? Kryssa i nedan </h3>  </>: ''}
            <input className="m-16 checkbox" type="checkbox" onChange={() => handleClick(note.id)} checked={note.isDone} />
          </div>
          <h2 className="pb-16">{note.nytitle}</h2>
          {note.number === 1 ?   InfoA1 : ''}
          {note.number === 5 ?   InfoA5 : ''}
          {note.number === 6 ?   InfoA6 : ''}
          {note.number === 7 ?   InfoA7 : ''}
          {note.number === 15 ?   InfoA15 : ''}
          {note.number === 16 ?   InfoA16 : ''}

          {note.catA && (
              <>
                <h3 className='p-16 pt-32'>{note.catA}</h3>

                <Accordion
                  // {note.number.toString() === 5? InfoCatA15 : '' }
                    title={<h3>Instruktioner</h3>}
                    parameter={`catA1`}
                    number={note.number.toString()}
                    onExpandClick={handleExpandClick}
                    imageData={imageData}
                />
                <select
                    className='center'
                    value={note.statA || ''}
                    onChange={(e) => handleStatusChange(e, note.id, 'statA')}
                >
                  <option value="">----Välj status----</option>
                  {statusValues.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                  ))}
                </select>
                <hr className='center mt-8' style={{color: "#bebdbd"}}/>

              </>
          )}
          {note.catB && (
              <>
                <h3 className='p-16 pt-32'>{note.catB}</h3>

                <Accordion
                    title={<h3>Instruktioner</h3>}
                    parameter={`catB1`}
                    number={note.number.toString()}
                    onExpandClick={handleExpandClick}
                    imageData={imageData}
                />
                <select
                    className='center'
                    value={note.statB || ''}
                    onChange={(e) => handleStatusChange(e, note.id, 'statB')}
                >
                  <option value="">----Välj status----</option>
                  {statusValues.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                  ))}
                </select>
                <hr className='center mt-8' style={{color: "#bebdbd"}}/>

              </>
          )}
          {/* Similar blocks for other categories */}
          {note.catC && (
              <>
                <h3 className='p-16 pt-32'>{note.catC}</h3>

                <Accordion
                    title={<h3>Instruktioner </h3>}
                    parameter={`catC1`}
                    number={note.number.toString()}
                    onExpandClick={handleExpandClick}
                    imageData={imageData}
                />
                <select
                    className='center'
                    value={note.statC || ''}
                    onChange={(e) => handleStatusChange(e, note.id, 'statC')}
                >
                  <option value="">----Välj status----</option>
                  {statusValues.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                  ))}
                </select>
                <hr className='center mt-8' style={{color: "#bebdbd"}}/>

              </>
          )}
          {note.catD && (
              <>
                <h3 className='p-16 pt-32'>{note.catD}</h3>

                <Accordion
                    title={<h3>Instruktioner</h3>}
                    parameter={`catD1`}
                    number={note.number.toString()}
                    onExpandClick={handleExpandClick}
                    imageData={imageData}
                />
                <select
                    className='center'
                    value={note.statD || ''}
                    onChange={(e) => handleStatusChange(e, note.id, 'statD')}
                >
                  <option value="">----Välj status----</option>
                  {statusValues.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                  ))}
                </select>
                <hr className='center mt-8' style={{color: "#bebdbd"}}/>

              </>
          )}
          {note.catE && (
              <>
                <h3 className='p-16 pt-16'>{note.catE}</h3>

                <Accordion
                    title={<h3>Instruktioner</h3>}
                    parameter={`catE1`}
                    number={note.number.toString()}
                    onExpandClick={handleExpandClick}
                    imageData={imageData}
                />
                <select
                    className='center'
                    value={note.statE || ''}
                    onChange={(e) => handleStatusChange(e, note.id, 'statE')}
                >
                  <option value="">----Välj status----</option>
                  {statusValues.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                  ))}
                </select>
                <hr className='center mt-8' style={{color: "#bebdbd"}}/>

              </>
          )}
          {note.catF && (
              <>
                <h3 className='p-16'>{note.catF}</h3>

                <Accordion
                    title={<h3>Instruktioner för {note.catF}</h3>}
                    parameter={`catF1`}
                    number={note.number.toString()}
                    onExpandClick={handleExpandClick}
                    imageData={imageData}
                />
                <select
                    className='center'
                    value={note.statF || ''}
                    onChange={(e) => handleStatusChange(e, note.id, 'statF')}
                >
                  <option value="">----Välj status----</option>
                  {statusValues.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                  ))}
                </select>
                <hr className='center mt-8' style={{color: "#bebdbd"}}/>

              </>
          )}
          {note.catG && (
              <>
                <h3 className='p-16'>{note.catG}</h3>

                <Accordion
                    title={<h3>Instruktioner för {note.catG}</h3>}
                    parameter={`catG1`}
                    number={note.number.toString()}
                    onExpandClick={handleExpandClick}
                    imageData={imageData}
                />
                <select
                    className='center'
                  value={note.statG || ''}
                  onChange={(e) => handleStatusChange(e, note.id, 'statG')}
              >
                  <option value="">----Välj status----</option>
                {statusValues.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                ))}
              </select>
                <hr className='center mt-8' style={{color: "#bebdbd"}}/>

              </>
          )}
        </div>
        <Popup
            isVisible={popupVisible}
            onClose={() => setPopupVisible(false)}
            onNavigate={() => {
              console.log("Navigating to /schools");
            }}
        />
      </>
  );
}

export default NoteItem;


