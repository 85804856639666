import React, { FC, useContext, useEffect, useState } from "react";
import { Context, teacherstore } from "../../index";
import { useNavigate, useParams } from "react-router-dom";
import TeacherNotesItem from "./TeacherNotesItem";
import { observer } from "mobx-react-lite";
import Spinner from "../../UI/Spinner/Spinner";

interface Note {
    studentId: string;
    studentName: string;
    catA: string;
    catB: string;
    catC: string;
}

interface ApiResponse {
    message: string;
    drivingNotes: Array<Note>;
}

const TeacherNotes: FC = () => {
    const navigate = useNavigate();
    const { studentId } = useParams();
    const [notes, setNotes] = useState<Array<Note>>([]);
    const [selectedStudentId, setSelectedStudentId] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const { teacherstore } = useContext(Context);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const response = await teacherstore.gNotes(teacherstore.teacher.id);
                if (Array.isArray(response.drivingNotes)) {
                    // @ts-ignore
                    setNotes(response.drivingNotes);
                } else {
                    console.error("Invalid response structure:", response);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchNotes();
    }, [teacherstore.teacher.id]);

    useEffect(() => {
        if (studentId) {
            setSelectedStudentId(studentId);
        }
    }, [studentId]);

    const uniqueStudentIds = Array.from(new Set(notes.map((note) => note.studentId)));

    const handleButtonClick = (studentId: string) => {
        setSelectedStudentId(studentId);
        navigate(`/instructorNotesGet/${studentId}`);
    };

    return (
        <div className='center pt-32 p-16' style={{ maxWidth: 340 }}>
            {loading ? (
                    <h3 className='center pt-64'>loading...</h3>
            ) : uniqueStudentIds.length > 0 ? (
                <div>
                    {uniqueStudentIds.map((studentId) => (
                        <div key={studentId} className='pt-64'>
                            <h3>Du har en elev med namnet:</h3>
                            <button
                                className='mt-16 button'
                                onClick={() => handleButtonClick(studentId)}
                            >
                                {notes.find((note) => note.studentId === studentId)?.studentName ?? ''}
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                <p>
                    Du har inga kort länkade till dig, vänligen prata med
                    din elev så att han/hon lägger till din e-mail address
                </p>
            )}

            {selectedStudentId && (
                <>
                    {notes
                        .filter((note) => note.studentId === selectedStudentId)
                        .map((card, index) => (
                            <TeacherNotesItem key={card.studentId} studentId={selectedStudentId} data={card} />
                        ))}
                </>
            )}
        </div>
    );
};

export default observer(TeacherNotes);
