import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Context } from '../../index';

const HamburgerMenu = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const { store, teacherstore } = useContext(Context);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const handleLogout = () => {
        store.logout();
        closeMenu();
    };

    const handleLogoutT = () => {
        teacherstore.logoutTeacher();
        closeMenu();
    };

    return (
        <div className={`wrapper main ${isMenuOpen ? 'menu-open' : ''}`}>
            <div className="menu-btn" onClick={toggleMenu}>
                <div className={`menu-icon ${isMenuOpen ? 'close' : ''}`}>
                    {isMenuOpen ? '✕' : '◎'}
                </div>
                <div className="menu-label">{isMenuOpen ? '' : 'menu'}</div>
            </div>

            <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
                <span>
                    <p className='pb-16'>
                        {store.isAuth || teacherstore.isAuthT ? (
                            <Link to="/" className='navtext' onClick={closeMenu}>Hem</Link>
                        ) : (
                            <Link to="/login" className='navtext' onClick={closeMenu}>Inloggningssida</Link>
                        )}
                    </p>
                    {store.isAuth && (
                        <>
                            <p>
                                <Link to="/notes" className='navtext' onClick={closeMenu}>Mitt övningskort</Link>
                            </p>
                            <p className='pt-16'>
                                <Link to="/instructor" className='navtext' onClick={closeMenu}>Lägg till handledare</Link>
                            </p>
                            <p className='pt-16'>
                                <Link to="/settings" className='navtext' onClick={closeMenu}>Inställningar</Link>
                            </p>
                            <p className='pt-32'>
                                <Link to="/" className='navtext' onClick={handleLogout}>Log out</Link>
                            </p>
                        </>
                    )}
                    {teacherstore.isAuthT && (
                        <>
                            <p>
                                <Link to="/instructornotes" className='navtext' onClick={closeMenu}>Elevens övningskort</Link>
                            </p>
                            <p className='pt-16'>
                                <Link to="/settings" className='navtext' onClick={closeMenu}>Inställningar</Link>
                            </p>
                            <p className='pt-32'>
                                <Link to="/" className='navtext' onClick={handleLogoutT}>Log out</Link>
                            </p>
                        </>
                    )}
                </span>
            </nav>
        </div>
    );
};

export default HamburgerMenu;
