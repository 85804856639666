
import React, { useMemo } from 'react';
import './index.css';
import dart from './dart.svg';

function MyStats({ drivingNotes }) {
    const uniqueGroupIds = useMemo(() => {
        return Array.from(new Set(drivingNotes.map(note => note.groupId)));
    }, [drivingNotes]);

    return (
        <div className="stats-container">
            {uniqueGroupIds.map((groupId, index) => {
                const groupNotes = drivingNotes.filter(note => note.groupId === groupId);
                const totalDoneInGroup = groupNotes.filter(note => note.isDone).length;
                const totalApprovedInGroup = groupNotes.reduce((total, note) => {
                    const statuses = [
                        note.statA,
                        note.statB,
                        note.statC,
                        note.statD,
                        note.statE,
                        note.statF,
                        note.statG,
                        note.statH
                    ];
                    const approvedCount = statuses.filter(status => status === '🟢Godkänd').length;
                    return total + approvedCount;
                }, 0);

                const count = uniqueGroupIds.length - index;

                return (
                    <div key={groupId} className="stat-item">
                        <img src={dart} style={{width: '42px', marginBottom: '8px'}}/>

                        <h3>Övningskort nummer: </h3>
                        <p>{count}</p>
                        <div className="stat-item">
                            <h3>Totalt avklarade lektioner:</h3>
                            <p>{totalDoneInGroup} av 16 </p>
                        </div>
                        <div className="stat-item">
                            <h3>Totalt godkända delmoment:</h3>
                            <p>{totalApprovedInGroup} av 74 delmoment</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default MyStats;
