// import { useEffect, useRef } from 'react';
//
// const useGlobalInactivity = (timeout: number, navigate: (to: string) => void, isActive: boolean) => {
//   const timeoutRef = useRef<number | null>(null);
//
//   const resetTimer = () => {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//
//     if (isActive) {
//       timeoutRef.current = window.setTimeout(() => {
//         sessionStorage.removeItem('token');
//         // sessionStorage.removeItem('user');
//         // sessionStorage.removeItem('teacher');
//         // sessionStorage.removeItem('tokenteacher');
//         // sessionStorage.removeItem('isAuthTeacher');
//         // sessionStorage.removeItem('isAuthUser');
//       }, timeout);
//     }
//   };
//
//   const handleActivity = () => {
//     resetTimer();
//   };
//
//   useEffect(() => {
//     window.addEventListener('click', handleActivity);
//     window.addEventListener('keydown', handleActivity);
//
//     resetTimer();
//
//     return () => {
//       window.removeEventListener('click', handleActivity);
//       window.removeEventListener('keydown', handleActivity);
//
//       if (timeoutRef.current) {
//         clearTimeout(timeoutRef.current);
//       }
//     };
//   }, [isActive, timeout, navigate]);
// };
//
//
// export default useGlobalInactivity;



import { useEffect, useRef } from 'react';

// @ts-ignore
const useGlobalInactivity = (timeout, onInactivity, isActive) => {
  const timeoutRef = useRef(null);

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (isActive) {
      // @ts-ignore
      timeoutRef.current = window.setTimeout(onInactivity, timeout);
    }
  };

  const handleActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    window.addEventListener('click', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimer();

    return () => {
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('keydown', handleActivity);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isActive, timeout, onInactivity]);
};

export default useGlobalInactivity;
