import React from 'react';
import notFoundVideo from '../../images/vid.gif';

const NotFoundPage = () => {
  return (
    <div className='center mt-64 m-16'>
      <h2>404 - Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      
      <img
         src={notFoundVideo} type="img/gif"/>
       
    </div>
  );
};

export default NotFoundPage;
