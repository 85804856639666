import React, { useState, useEffect } from 'react';
import NoteService from '../../../services/NoteService';
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";
import debounce from 'lodash.debounce';
import parse from 'html-react-parser';

const Accordion = ({ title, parameter, onExpandClick, id, number }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentWithImages, setContentWithImages] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 868);

    useEffect(() => {
        const handleWindowSizeChangeDebounced = debounce(() => {
            setIsMobile(window.innerWidth <= 869);
        }, 200);

        window.addEventListener('resize', handleWindowSizeChangeDebounced);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChangeDebounced);
            handleWindowSizeChangeDebounced.cancel();
        };
    }, []);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await NoteService.getDescription(parameter, number);
                const paragraphs = response.data[parameter].split('\n');
                const imageUrls = response.data[`${parameter}img`] || [];
                const contentWithImages = paragraphs.map((paragraph, index) => ({
                    paragraph: replaceMarkersWithImages(processText(paragraph), imageUrls),
                    paragraphNumber: index + 1
                }));

                if (isMounted) {
                    setContentWithImages(contentWithImages);
                }
            } catch (error) {
                console.error(`Error fetching data for ${parameter}${number}:`, error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [parameter, number]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
        onExpandClick(parameter, number);
    };

    const processText = (text) => {
        const stegRegex = /steg\s(1[0-9]|20|[1-9])/gi;
        const fallRegex = /fall\s(1[0-9]|20|[1-9])/gi;
        const automatRegex = /-AUTOMAT-/gi;
        const numberDotRegex = /(?:^|\s)(1[0-9]|20|[1-9])\./g;

        return text
            .replace(stegRegex, match => `<strong class="highlighted">${match}</strong>`)
            .replace(automatRegex, match => `<strong class="highlighted2">${match}</strong>`)
            .replace(fallRegex, match => `<strong class="highlighted">${match}</strong>`)
            .replace(numberDotRegex, match => `<strong class="highlighted">${match}</strong>`);
    };

    const replaceMarkersWithImages = (text, imageUrls) => {
        return text.replace(/\[cat([A-G])([1-9]|[1-9][0-9]|1[0-9]{2}|200)_(1[0-9]|20|[1-9])\]/g, (match, catGroup, number, index) => {
            const imageIndex = parseInt(index, 10) - 1;
            const imageUrl = imageUrls[imageIndex];
            if (imageUrl) {
                // Instead of a div, use an inline element like <span> or directly an <img>
                return `
                <span style="display: flex; justify-content: center; padding: 8px 0;">
                    <img src="${imageUrl}" alt="Step image" style="max-width:100%; height:50%;" />
                </span>`;
            }
            return match;
        });
    };


    return (
        <>
            {isMobile ? (
                <div className="accordion-m center">
                    <div
                        className="accordion-header center2"
                        style={{ backgroundColor: '#f1ebff', padding: '10px', marginBottom: '-5px', borderRadius: '5px' }}
                        onClick={toggleAccordion}
                    >
                        <span>{title}</span>
                        {isOpen ? <MdOutlineExpandLess className="custom-icon" /> : <MdOutlineExpandMore className="custom-icon" />}
                    </div>
                    {isOpen && (
                        <div style={{ backgroundColor: '#f1ebff', padding: '9px', borderRadius: '5px' }}>
                            {contentWithImages.map(({ paragraph, paragraphNumber }) => (
                                <div key={paragraphNumber}>
                                    <p style={{ textAlign: 'left' }}>{parse(paragraph)}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className="accordion center">
                    <div
                        className="accordion-header"
                        style={{ backgroundColor: '#f1ebff', paddingTop: '10px', marginBottom: '-5px', borderRadius: '5px' }}
                        onClick={toggleAccordion}
                    >
                        <span>{title}</span>
                        {isOpen ? <MdOutlineExpandLess className="custom-icon" /> : <MdOutlineExpandMore className="custom-icon" />}
                    </div>
                    {isOpen && (
                        <div className="accordion-content" style={{ backgroundColor: '#f1ebff', padding: '15px', borderRadius: '5px' }}>
                            {contentWithImages.map(({ paragraph, paragraphNumber }) => (
                                <div key={paragraphNumber} >
                                    <p style={{ textAlign: 'left' }}>{parse(paragraph)}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Accordion;
