import React from 'react';
import './toggle.css';


interface ToggleProps {
    toggle4: boolean;
    handleToggleChange4: () => void;
    toggle5: boolean;
    handleToggleChange5: () => void;
}

export const e = <span className='category'>A</span>;
export const f = <span className='category'>A</span>;

export const Toggle4: React.FC<ToggleProps> = ({ toggle4, handleToggleChange4, toggle5, handleToggleChange5 }) => {
    return (
        <div onClick={handleToggleChange4}>
            <div className={`toggle-btn ${!toggle4 ? 'disable' : ''}`}>
                {toggle4 ? e : f}
            </div>
        </div>
    );
};