import React, { useState, useEffect, useContext, useMemo } from 'react';
import NoteService from '../../services/NoteService';
import './App.css';
import NoteItem from "./NoteItem";
import todosData from "./TodosData";
import { Context } from "../../index";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import DeleteConfirmationModal from './DeleteConfirmation'
import Spinner from '../../UI/Spinner/Spinner';
import Accordion from "./Accordion/Accordion";
import MyStats from "./MyStats/Mystats";


function DrivingNotes() {
    const [drivingNotes, setDrivingNotes] = useState([]);
    const [statusValues, setStatusValues] = useState(['🟣Börjat', '🟠Visad kunskap', '🟢Godkänd']);

    //modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingGroupId, setDeletingGroupId] = useState(null)

    const { store } = useContext(Context);

    const [loading, setLoading] = useState(false)

    const headers = {
        'studentId': sessionStorage.getItem('studentId'), // Retrieve user ID from localStorage
    };

    useEffect(() => {
      setLoading(true);
      NoteService.getAllDrivingNotes({ headers })
        .then(response => {
          const sortedDrivingNotes = response.data.drivingNotes.sort((a, b) => {
            if (a.groupId !== b.groupId) {
              return a.groupId.localeCompare(b.groupId);
            }
            return a.number - b.number;
          });

          setDrivingNotes(sortedDrivingNotes);
        })
        .catch(error => {
          // console.error('Error fetching data:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    // Function to update a driving note
    const updateDrivingNote = async (note, field, value) => {
        const updatedNote = { ...note, [field]: value };
        try {
            await NoteService.updateDrivingNoteById(
                updatedNote.id,
                updatedNote.nytitle,
                updatedNote.statA,
                updatedNote.statB,
                updatedNote.statC,
                updatedNote.statD,
                updatedNote.statE,
                updatedNote.statF,
                updatedNote.statG,
                updatedNote.statH,
                updatedNote.isDone
            );
        } catch (error) {
            // console.error('Error updating note: ', error);
        }
    };
    // Handle status change for a specific field (e.g., statA, statB, etc.)
    const handleStatusChange = (e, drivingNoteId, fieldName) => {
        const newStatus = e.target.value;
        // Update the local state
        setDrivingNotes((prevDrivingNotes) => {
            return prevDrivingNotes.map((note) => {
                if (note.id === drivingNoteId) {
                    return { ...note, [fieldName]: newStatus };
                }
                return note;
            });
        });
        // Update the backend data
        const noteToUpdate = drivingNotes.find((note) => note.id === drivingNoteId);
        updateDrivingNote(noteToUpdate, fieldName, newStatus);
    };
    // Handle the click event for the checkbox *is done
    const handleClick = (drivingNoteId) => {
        const updatedDrivingNotes = drivingNotes.map((note) => {
            if (note.id === drivingNoteId) {
                return {
                    ...note,
                    isDone: !note.isDone,
                };
            }
            return note;
        });
        setDrivingNotes(updatedDrivingNotes);
        const noteToUpdate = drivingNotes.find((note) => note.id === drivingNoteId);
        updateDrivingNote(noteToUpdate, 'isDone', !noteToUpdate.isDone);
    };


    const createNewNotes = async () => {
        setLoading(true)
        try {
            const newNotes = [];
            const newGroupId = v4();
            for (const item of todosData.items) {
                // Create a new note object with the new groupId and item data
                const newNote = {
                    groupId: newGroupId,
                    id: v4(),
                    studentId: store.user.id,
                    isDone: item.isDone,
                    number: item.number,
                    nytitle: item.nytitle,
                    catA: item.catA,
                    statA: '',
                    catB: item.catB,
                    statB: '',
                    catC: item.catC,
                    statC: '',
                    catD: item.catD,
                    statD: '',
                    catE: item.catE,
                    statE: '',
                    catF: item.catF,
                    statF: '',
                    catG: item.catG,
                    statG: '',
                    catH: item.catH,
                    statH: '',
                };
                newNotes.push(newNote);
            }
            await Promise.all(newNotes.map(async (note) => await NoteService.createDrivingNote(note)));
            const updatedResponse = await NoteService.getAllDrivingNotes();

            const sortedDrivingNotes = updatedResponse.data.drivingNotes.sort((a, b) => {
                if (a.groupId !== b.groupId) {
                    return a.groupId.localeCompare(b.groupId);
                }
                return a.number - b.number;
            });

            setDrivingNotes(sortedDrivingNotes);
            toast.success(` Ny lista skapad!`);
        } catch (error) {
            console.error('Error creating new note: ', error);
        }
        finally
        { setLoading(false) }
    };


    const deleteGroup = (groupId) => {
        setDeletingGroupId(groupId);
        setShowDeleteModal(true);
    };

    // Function to handle the actual deletion when confirmed
    const handleDeleteConfirmed = async () => {
        setLoading(true)

        try {
            await NoteService.deleteDrivingNoteById(deletingGroupId);
            setDrivingNotes((prevDrivingNotes) => prevDrivingNotes.filter((n) => n.groupId !== deletingGroupId));
            toast.info(` Kortet är raderad!`);
        } catch (error) {
            console.error('Error deleting notes: ', error);
        } finally {
            // Reset modal state after deletion
            setLoading(false)

            setShowDeleteModal(false);
            setDeletingGroupId(null);
        }
    };

    // Function to handle cancellation of deletion
    const handleDeleteCanceled = () => {
        setShowDeleteModal(false);
        setDeletingGroupId(null);
    };

      const hasAnyCats = useMemo(() => {
        return (note) => {
          return note.catA || note.catB || note.catC || note.catD || note.catE || note.catF || note.catG || note.catH;
        };
      }, []);

      let count = Array.from(new Set(drivingNotes.map((note) => note.groupId))).length;


    return ( <div className="mt-64 jcc center">
                <button className="ghostbutton" style={{ maxWidth: 198 }} onClick={createNewNotes}>
                  Skapa nytt kort
                </button>
              <MyStats drivingNotes={drivingNotes} />
              <div className="jcc center">
              {loading ?
            <Spinner /> :
            <div className="center container m-8" style={{ paddingTop: '20px' }}>
              {Array.from(new Set(drivingNotes.map((note) => note.groupId))).reverse().map((groupId, index) => {
                const groupNotes = drivingNotes.filter((note) => note.groupId === groupId);
                if (groupNotes.some(hasAnyCats)) {
                  const groupCount = count - index;

                  return (
                    <div key={index} className="center card mt-32" style={{ paddingTop: "30px", backgroundColor: "#e9e9e9" }}>
                      <span>Kort nummer</span>
                      <p style={{ fontSize: "5rem", color: "#bebdbd" }}>{groupCount}</p>
                      <button
                        className="ghostbutton"
                        style={{ maxWidth: 135 }}
                        onClick={() => deleteGroup(groupId)}
                      >
                        Ta bort
                      </button>

                      <div key={groupId} className="center container m-8" >
                        {groupNotes.map((note) => (
                          <NoteItem
                            key={note.id}
                            note={note}
                            number={note.number}
                            groupId={note.groupId}
                            handleClick={handleClick}
                            handleStatusChange={handleStatusChange}
                            statusValues={statusValues}
                          />
                        ))}
                      </div>
                    </div>
                  )
                } else {
                  return null;
                }
              })}
              <DeleteConfirmationModal
                isOpen={showDeleteModal}
                onCancel={handleDeleteCanceled}
                onConfirm={handleDeleteConfirmed}
              />
            </div> }
            </div>
            </div>
      )

      }

      export default DrivingNotes;