import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../index';
import { updatedeals } from '../../services/StudentService';
import { toast } from 'react-toastify';
import carGif from '../../../src/images/car.gif';

const Deals = () => {
    const navigate = useNavigate();
    const { store } = useContext(Context);
    const [isUpdating, setIsUpdating] = useState(false); // Track update state
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false; // Cleanup function to set isMounted to false on unmount
        };
    }, []);

    const handleButtonClick = async () => {
        setIsUpdating(true); // Set update progress indicator

        try {
            await updatedeals({ email: store.user.email }); // Destructure email
            if (isMounted.current) {
                toast.success('Toppen! Vi hör av oss.');
                navigate('/login');
            }
        } catch (error) {
            if (isMounted.current) {
                console.error('Error updating deals:', error);
                toast.error('Ett fel uppstod.'); // Inform user of error
            }
        } finally {
            if (isMounted.current) {
                setIsUpdating(false); // Reset update progress indicator
            }
        }
    };

    return (
        <div className='card center mt-64' style={{ maxWidth: 360, minHeight: 300 }}>
            <div className='m-16'>
                <h3 className='p-16 pb-32'>
                    Vi jobbar på det! Snart har du en lista av exklusiva deals redo.
                </h3>
                <br />
                <img src={carGif} alt="Car animation" width="270" height="180" style={{ borderRadius: '9px' }} />
                <h3 className='p-16 pb-32'>
                    Meddela mig när allt är klart
                </h3>
                <button
                    onClick={handleButtonClick}
                    style={{ maxWidth: 400 }}
                    className='ghostbutton'
                    disabled={isUpdating} // Disable button during update
                >
                    {isUpdating ? 'Uppdaterar...' : 'Ja tack!'}
                </button>
            </div>
        </div>
    );
};

export default Deals;
