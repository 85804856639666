// Axios call with password in headers
import $api, { API_URL } from "../http/index";
import { AxiosResponse } from 'axios';
import { AuthResponse } from "../pages/models/response/AuthResponse";

export async function newpass(newPass: string, studentId: string): Promise<AxiosResponse<AuthResponse>> {
  const data = { newPass: newPass };
  return $api.put(`v1/setNewPuser/${studentId}`, data);
}

export async function deluser ( studentId: string): Promise<AxiosResponse<AuthResponse>>  {

  return $api.delete(`/v1/delUser/${studentId}`);
  }


export async function getdatausr(studentId: string): Promise<AxiosResponse<AuthResponse>> {
  try {
    const response = await $api.get(`v1/getUserData/${studentId}`);
    return response;
  } catch (error) {
    throw '';
  }
}

export interface UserDataUpdate {
  name?: string;
  surname?: string;
  pnr?: string; // Assuming pnr is a string, update the type accordingly
  phone?: string; // Assuming phonenumber is a string, update the type accordingly
  category?: string[]; // Updated to array of strings
  autotransmission?: boolean;
}

export async function svdatausr(studentId: string, data: UserDataUpdate): Promise<AxiosResponse<AuthResponse>> {
  try {
    const response = await $api.put(`v1/updUserData/${studentId}`, data);
    return response; // Add this line to return the response
  } catch (error) {
    throw '';
  }
}
export interface UserDataUpdate2 {

  autotransmission?: boolean;
}


export async function svdatausr2(studentId: string, data: UserDataUpdate2): Promise<AxiosResponse<AuthResponse>> {
  try {

    const response = await $api.put(`v1/updUserData2/${studentId}`, data);
    return response; // Add this line to return the response
  } catch (error) {
    throw '';
  }
}



export async function updatedeals(email: string, ): Promise<AxiosResponse<AuthResponse>> {
  try {

    const response = await $api.put(`/v1/dealsreminder`, email);
    return response; // Add this line to return the response
  } catch (error) {
    throw '';
  }
}

export async function controllesson(email: string, ): Promise<AxiosResponse<AuthResponse>> {
  try {

    const response = await $api.put(`/v1/controllesson`, email);
    return response; // Add this line to return the response
  } catch (error) {
    throw '';
  }
}
