// import React, { useContext, useState } from 'react';
// import { useForm, Resolver } from "react-hook-form";
// import { Context } from "../../index";
// import '../../style.css'
// import { useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import { useNavigate } from 'react-router-dom';
//
// type FormValues = {
//     password: string;
// };
//
// export default function ChangePasswordTeacher() {
//     const {
//         register,
//         handleSubmit,
//         formState: { errors }
//     } = useForm<FormValues>({
//     });
//     const { teacherstore } = useContext(Context);
//     const [password, setPassword] = useState<string>('password')
//     const { restoreToken, studentId } = useParams<string>();
//     const studentI = studentId
//     const restoreTok = restoreToken
//     const navigate = useNavigate();
//
//     const handleClick = () => {
//
//         navigate('/instructorLogIn');
//         window.location.reload();
//     }
//
//     //@ts-ignore
//     const onSubmit = handleSubmit(() => teacherstore.changepassTeacher(password, studentId, restoreToken).then(res => toast.success(`Ditt lösenord är nu ändrat!`)).then(handleClick));
//
//     return (
//         <div className='center pt-32' style={{ maxWidth: 350 }}>
//             <div className='center pb-32 pt-32'>
//                 <h3>Uppdateda ditt lösenord</h3>
//                 <h4 className='center pt-16'>Skriv nedan ditt nya lösendord minst 8 tecken</h4>
//             </div>
//
//             <form onSubmit={onSubmit}>
//                 <div>
//                     <input
//                         {...register("password", {
//                             required: "Vänligen skriv ditt lösenord , minst 8 tecken",
//                             minLength: {
//                                 value: 8,
//                                 message: "Lösenord måste vara minst 8 tecken",
//                             },
//                         })}
//                         type="password"
//                         onChange={e => setPassword(e.target.value)}
//                         placeholder="Skriv ditt nya lösenord här"
//                     />
//                 </div>
//                 {errors?.password && <p className='description error'>{errors.password.message}</p>}
//                 <button type="submit" className="button" >Skapa nytt lösenord</button>
//             </form>
//         </div>
//     );

// }
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Context } from "../../index";
import '../../style.css';
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

type FormValues = {
    password: string;
};

export default function RecoverPage() {
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();
    const { teacherstore } = useContext(Context);
    const [password, setPassword] = useState<string>('');
    const { restoreToken, teacherId } = useParams<{ restoreToken: string, teacherId: string }>();
    const navigate = useNavigate();
    const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

    useEffect(() => {
        if (restoreToken) {
            teacherstore.checkRestT(restoreToken)
                .then(() => {
                    setIsTokenValid(true);
                })
                .catch(() => {
                    setIsTokenValid(false);
                });
        }
    }, [restoreToken, teacherstore]);

    const handleClick = () => {
        navigate('/');
        window.location.reload();
    }

    const onSubmit = handleSubmit(async () => {
        try {
            //@ts-ignore
            await teacherstore.changepassTeacher(password, teacherId, restoreToken);
            toast.success('Lösenordet är nu ändrat!');
            handleClick();
        } catch (e) {
            toast.error('Ett fel uppstod när lösenordet skulle ändras.');
        }
    });

    if (isTokenValid === null) {
        return <div className={'center pt-64'}>Laddar...</div>;
    }

    if (!isTokenValid) {
        // @ts-ignore
        return <div className={'center pt-64'}>
            <h3>Inaktuell återställningslänk, vänligen försök igen genom att återställa lösenord på nytt</h3>
            <a onClick={() => navigate("/recoverPassword")} className={'pt-32'}>Återställ här</a>
        </div>;
    }

    return (
        <div className='center pt-32' style={{ maxWidth: 350 }}>
            <div className='center pb-32 pt-32'>
                <h3>Uppdatera ditt lösenord</h3>
                <h4 className='center pt-16'>Välj ditt lösenord, minst 8 tecken</h4>
            </div>

            <form onSubmit={onSubmit}>
                <div>
                    <input
                        {...register("password", {
                            required: "Vänligen skriv ditt lösenord, minst 8 tecken",
                            minLength: {
                                value: 8,
                                message: "Lösenordet måste vara minst 8 tecken",
                            },
                        })}
                        type="password"
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Skriv ditt nya lösenord här"
                    />
                </div>
                {errors?.password && <p className='description error'>{errors.password.message}</p>}
                <button type="submit" className="button">Skapa nytt lösenord</button>
            </form>
        </div>
    );
}
