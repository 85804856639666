import { ITeacher } from "../pages/models/ITeacher";
import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import TeacherService from "../services/TeacherService";
import axios from 'axios';
import { toast } from "react-toastify";
import { API_URL2 } from "../http/index2";
import { AuthResponse2 } from "../pages/models/response/AuthResponse2";

export default class Teacherstore {
    teacher = {} as ITeacher;
    isAuthT = false;
    isLoading = false;
    lastActivityTime: number = 0;

    setLastActivityTime(currentTime: number) {
        this.lastActivityTime = currentTime;
    }

    constructor() {
        makeAutoObservable(this);
        this.loadSessionData();

    }

    setAuthT(bool: boolean) {
        this.isAuthT = bool;
        sessionStorage.setItem("isAuthTeacher", JSON.stringify(bool));
    }

    setTeacher(teacher: ITeacher) {
        this.teacher = teacher;
        sessionStorage.setItem("teacher", JSON.stringify(teacher));
    }



    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    loadSessionData() {
        const storedTeacher = sessionStorage.getItem("teacher");
        const storedIsAuthT = sessionStorage.getItem("isAuthTeacher");

        if (storedTeacher) {
            this.teacher = JSON.parse(storedTeacher);
        }
        if (storedIsAuthT) {
            this.isAuthT = JSON.parse(storedIsAuthT);
        }
    }




    // @ts-ignore
    async registrationTeacher(name: string, email: string, password: string, city: string, category: string[]): Promise<{ status: number; message?: string }> {
        try {
            const response = await AuthService.registrationT(name, email, password, city, category);
            return { status: 200 };
        } catch (error) {
            // @ts-ignore
            toast.error(error.response?.data?.message );
        }
    }


    async loginTeacher(email: string, password: string) {
        try {
            const response = await AuthService.loginTeacher(email, password, { withCredentials: true });

            sessionStorage.setItem("tokenteacher", response.data.accessToken);
            this.setAuthT(true);
            this.setTeacher(response.data.teacher);
            toast.success(`Välkommen ${response.data.teacher.email}`);
        } catch (error) {
            // @ts-ignore
            if (error.response && error.response.status === 429) {
                throw error;
            } else {
                // Handle other errors (e.g., network error, server error)
                // @ts-ignore
                toast.error(error.response?.data?.message );
            }
        } finally {
            this.setLoading(false);
        }
    }


    async logoutTeacher() {
        try {
            const response = await AuthService.logoutTeacher();
            this.setAuthT(false);
            this.setTeacher({} as ITeacher);
            sessionStorage.removeItem('tokenteacher');
            sessionStorage.removeItem('teacher');
            sessionStorage.removeItem('isAuthTeacher');
            sessionStorage.clear();
            toast.success("Log out complete")
        } catch (e) {
            // @ts-ignore
            toast.error(e.response?.data?.message)
        }

    }




    async changepassTeacher(password: string, teacherId: string, restoreToken: any) {
        try {
            const response = await AuthService.chngpassTeacher(password, teacherId, restoreToken, { withCredentials: true });
            // @ts-ignore
            localStorage.setItem('restoreToken', response.data.restoreToken);
            window.location.reload();

        } catch (e) {
            // @ts-ignore
            toast.error(e.response?.data?.message)
        }
    }

    async recoverTeacher(email: string) {
        try {
            await AuthService.recoverTeacher(email);
        } catch (e) {
        }
    }

    //////////////////// teacher //////////////

    // @ts-ignore
    async gNotesid({ studentId, teacherId }) {
        try {
            const queryParams = { studentId, teacherId };
            const response = await TeacherService.getTeacherNoteById(queryParams);
            return response.data;
        } catch (e) {
        }
    }

    async gNotes(teacherId: string) {
        try {
            const response = await TeacherService.getNotes()
            return response.data;
        } catch (e) {
            throw '';
        }
    }



    async checkRestT(restoreToken: string) {
        try {
            const response = await AuthService.checkRestT(restoreToken,{ withCredentials: true }) ;
            if (response.data.success) {
                return true;
            } else {
                throw new Error("Invalid or expired token");
            }
        } catch (e) {
            // @ts-ignore
            throw new Error(e.response?.data?.message || "Failed to validate token");
        }
    }


}



