import $api, { API_URL } from "../http/index";
import $api2, { API_URL2 } from "../http/index2";
import { AxiosResponse } from 'axios';
import { AuthResponse } from "../pages/models/response/AuthResponse";
import { IUser } from "../pages/models/IUser";
import { AuthResponse2 } from "../pages/models/response/AuthResponse2";
import { ITeacher } from "../pages/models/ITeacher";

export default class AuthService {

    static async login(email: string, password: string, config?: any ): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('v1/login', { email, password }, config);
    }

    static  async  registration(name: string, email: string, password: string, city: string, category: string[], config?: any): Promise<AxiosResponse<AuthResponse>> {
        try {
            const response = await $api.post<AuthResponse>('/registration', {
                name,
                email,
                password,
                city,
                category,
            }, config);

            return response;
        } catch (error) {
            throw error;
        }
    }

    static  async  registrationT(name: string, email: string, password: string, city: string, category: string[]): Promise<AxiosResponse<AuthResponse2>> {
        try {
            const response = await $api2.post<AuthResponse2>('v1/teacher/registration', {
                name,
                email,
                password,
                city,
                category,
            });

            return response;
        } catch (error) {
            throw error;
        }
    }


    static async logout(): Promise<void> {
        return $api.post('v1/logout')
    }


    static async loginTeacher(email: string, password: string, config?: any ): Promise<AxiosResponse<AuthResponse2>> {
        try {
            const response = await $api2.post<AuthResponse2>('/v1/teacher/login', { email, password }, config);
            const accessToken = response.data?.accessToken;
            if (accessToken) {
                sessionStorage.setItem('tokenteacher', accessToken);
            }
            return response;
        } catch (error) {
            throw error;
        }
    }




    ///recover user
    static async recover(email: string): Promise<AxiosResponse<IUser>> {
        return $api.post('v1/passwordReset', { email })
    }


    static async pass(password: any, studentId: any, restoreToken: any, config?: any ): Promise<AxiosResponse<AuthResponse>> {
        return $api.post('v1/passwordReset/:studentId/:restoreToken', {password, params: { studentId, restoreToken },  config})
    }

    static async checkRest(restoreToken: string, config?: any): Promise<AxiosResponse<any>> {
        return $api.get(`/v1/passwordReset/${restoreToken}`, config);
    }


    ///recoverTeacer///////////
    static async recoverTeacher(email: string): Promise<AxiosResponse<ITeacher>> {
        return $api2.post('v1/teacher/passwordReset1', { email })
    }

    static async chngpassTeacher(password: any, teacherId: any, restoreToken: any,  config?: any): Promise<AxiosResponse<AuthResponse2>> {
        // return $api2.post('v1/teacher/passwordReset1/:teacherId/:restoreToken', { password, params: { teacherId, restoreToken }, config })
        return $api2.post(`v1/teacher/passwordReset1/${teacherId}/${restoreToken}`, { password }, config);

    }

    ///new
    static async checkRestT(restoreToken: string, config?: any): Promise<AxiosResponse<any>> {
        return $api2.get(`/v1/passwordReset1/${restoreToken}`, config);
    }

    static async logoutTeacher(): Promise<void> {
        return $api2.post('v1/teacher/logout')

    }

}


