import React, {useContext, useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TeacherService, { addTeacher } from '../../services/TeacherService';
import '../../style.css';
import { useNavigate } from 'react-router-dom';
import NoteService from '../../services/NoteService';
import { ImBin } from 'react-icons/im';
import {Context} from "../../index";
import Spinner from '../../UI/Spinner/Spinner';
import inst from '../../../src/images/instructor.svg';


const AddTeacher = () => {
    const navigate = useNavigate();
    const [teacherEmail, setTeacherEmail] = useState('');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [notesMessage, setNotesMessage] = useState(null);
    const [teacherName, setTeacherName] = useState('');
    const [teacherId, setTeacherId] = useState('');
    const [isFormVisible, setFormVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState( false);
    const [errorMessageHand, setErrorMessageHand] = useState( false);

    const [showErrorDiv, setShowErrorDiv] = useState(true); // State to control the visibility of the error div

    const { store } = useContext(Context);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const studentId = store.user.id
                const response = await TeacherService.checkNotes(studentId);

                if (response.data.message === 'User does have notes') {
                    setNotesMessage('User has driving notes');
                } else if (response.data.data.message === 'User does not have any driving notes') {
                    setNotesMessage('User does not have any driving notes');
                }
            } catch (error) {
                // console.error('Error fetching notes:', error);
                setNotesMessage('Något hände... vänligen försök igen');
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchTeacherName = async () => {
            try {
                // const studentId = localStorage.getItem('userId');
                const studentId = store.user.id

                const response = await NoteService.checkNameT(studentId);
                const { teacherName, teacher } = response.data;
                setTeacherName(teacherName);
                setTeacherId(teacher);
            } catch (error) {
                // console.error('An error occurred while fetching teacherName:', error);
            }
        };

        fetchTeacherName();
    }, []);




    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const studentId = store.user.id;
            await addTeacher(data.email, studentId);
            toast.success('Handledare är nu tillagd');
            navigate('/login');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setErrorMessage(true);
                setShowErrorDiv(true); // Show the error div when a 400 error occurs

            } else {
                toast.error('Ingen handledare med den här e-mail');
                setErrorMessageHand(true); // Show the error div when a 400 error occurs

            }
        } finally {
            setLoading(false);
        }
    };


    const handleRemove = async () => {
        try {
            const studentId = store.user.id
            await NoteService.delHand(teacherId, studentId);
            toast.success('Handledare är nu borttagen')
            setTeacherName(''); // Update the teacherName state to trigger a re-render

        } catch (error) {
            // console.error('An error occurred while deleting:', error);
        }
    };

    const handleButtonClick = () => {
        handleRemove();
        setFormVisible(true);
    };

    return (<>
        {errorMessage && showErrorDiv && (
            <div className={'center pt-32 p-32'} style={{ width: 340, height: 200, backgroundColor: '#ffdbdb', borderRadius: '7px' }}>
                Du finns ingen handledare med den här e-mail, vänligen försök igen
                <button className='ghostbutton mt-32' onClick={() => setShowErrorDiv(false)}>OK</button>
            </div>
        )}

        {loading? <div><Spinner /></div> :

        <div className='jcc card center mt-32' style={{ maxWidth: 360 }}>

            <img src={inst} alt="Car animation" width="280" height="280" />

            {notesMessage === 'User has driving notes' ? (
                teacherName ? (
                    <div className='p-16'>
                        <h3>Din handledare</h3>
                        <p className={'pt-16 pb-16'} style={{fontSize: "20pt"}}>{teacherName}</p>
                        <button className={'button'} onClick={handleButtonClick}>
                            <ImBin /> Ta bort handledare
                        </button>
                    </div>
                ) : (
                    <div className='p-16'>
                        <p className='p-16'>Här kan du lägga till en registrerad handledare som kan "kryssa i" ditt övningskort medans du kör bilen. </p>
                        <p className='p-16 pt-32'>OBS! Handledare måste vara registrerad hos oss innan du lägger till honom.</p>

                        <h3 className='pt-16'>Skriv handledarens e-mail</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='pt-32'>
                                <input
                                    {...register('email', {
                                        required: 'Vänligen skriv email av din handledare',
                                        validate: (value) => /\S+@\S+\.\S+/.test(value) || 'Felaktig e-mailadress. Vänligen kontrollera inmatad e-mail.',
                                    })}
                                    onChange={(e) => setTeacherEmail(e.target.value)}
                                    placeholder='Handledarens e-mail skrivs här'
                                />
                            </div>
                            {errors?.email && <p className='description error'>{errors.email.message}</p>}

                            {errorMessageHand? <p className='description error'> Ingen handledare med den här е-mail</p> :''}
                            <button type='submit' className='button'>
                                Lägg till
                            </button>
                        </form>
                    </div>
                )

            ) : (
                <div className='m-16'>
                    <h3 className='p-16 pb-32'>
                        Du har inga kort.
                        <br />
                        För att lägga till en handläggare måste du skapa ett övningskort först
                    </h3>
                    <button onClick={() => navigate('/notes')} style={{ maxWidth: 400 }} className='ghostbutton'>
                        Skapa den här
                    </button>
                </div>
            )}
        </div>
        }
   </> );
};

export default AddTeacher;

