import React from 'react';

const DeleteConfirmationModal = ({ isOpen, onCancel, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay p-8">
            <div className="modal cards center">
                <p className=' pt-16'>Du är på väg att ta bort ditt övningskort</p>
                <div className="modal-buttons pt-32 cards">
                    <button className='ghostbutton' style={{ height: "54px" }} onClick={onCancel}>Avbryt</button>
                    <button className='button ml-16'  onClick={onConfirm}>Ta bort</button>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirmationModal;
