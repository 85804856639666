import '../../style.css';
import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import {Context, teacherstore} from "../../index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TeacherLoginForm from "./TeacherLoginForm";
import { ITeacher } from "../models/ITeacher";
import Spinner from "../../UI/Spinner/Spinner";

const TeacherLoginPage: FC = () => {
    const { teacherstore } = useContext(Context);
    const navigate = useNavigate();

    const [teachers, setTeachers] = useState<ITeacher[]>([]);

    useEffect(() => {
        if (teacherstore.isAuthT) {
            setTimeout(() => {
                teacherstore.logoutTeacher()
            }, 3 * 60 * 60 * 1000); // 10 seconds (10000 milliseconds)
            // Add event listener to reset inactivity timer on button clicks
            window.addEventListener('click', () => {
                teacherstore.setLastActivityTime(Date.now()); // Update last activity time
            });

            return () => {
                window.removeEventListener('click', () => {
                    teacherstore.setLastActivityTime(Date.now()); // Update last activity time
                });
            };
        }
    }, [teacherstore.isAuthT]);


    if (teacherstore.isLoading) {
        return <div><Spinner/></div>
    }

    if (!teacherstore.isAuthT) {
        return (
            <div className='jcc center mt-64 mb-32' style={{ maxWidth: 350 }}>
                <TeacherLoginForm />
            </div>
        );
    }


    return (

        <div className='center pt-32 p-16' style={{ maxWidth: 360 }}>
            <h2 className='pb-16'>Välkommen till Let's roll.</h2>
            <h5 className='center pt-32'> {teacherstore.isAuthT ? `Inloggad som ${teacherstore.teacher.email}` : 'Please log in'}</h5>
            <h4 className='center pt-32'> Handledare </h4>
            <div className='center pt-32 mb-16'>
                {teacherstore.teacher.isActivated ? (
                    <div>
                    <span>
                        <button onClick={() => navigate("/instructornotes")} className="button" >Mina övningskörningskort</button>
                    </span>
                       
                       <span>
                        <button onClick={() => navigate("/settingsT")} className="button" >Mina inställningar</button>
                    </span>
                    </div>
                ) : (

                    <p style={{ fontSize: "1.5rem" }}>Vänligen aktivera ditt konto (aktiverings e-mail finns i din brevlåda)</p>
                )}
            </div>


            <button className='mt-64 button' onClick={() => teacherstore.logoutTeacher()}>Log out</button>

        </div>


    );
};

export default observer(TeacherLoginPage);