import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../index';
import { CSSTransition } from 'react-transition-group';
import './StartPage.css';
import Spinner from "../../UI/Spinner/Spinner";

const videoSource = require('../StartPage/output.mp4');

const StartPage = () => {
    const { teacherstore } = useContext(Context);
    const [isLoading, setIsLoading] = useState(true);
    const videoRef = useRef(null);
    const navigate = useNavigate();

    const handleLoadedData = () => {
        setIsLoading(false);
        const video = videoRef.current;
        if (video) {
            video.play();
        }
    };

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.controls = false; // Ensure controls are hidden
            video.addEventListener('loadeddata', handleLoadedData);

            // Ensure the video plays on mount
            video.play().catch(error => {
                console.log("Video playback failed:", error);
            });
            return () => {
                video.removeEventListener('loadeddata', handleLoadedData);
            };
        }
    }, []);

    return (
        <div className="start-page">
            <div className="video-background mt-64">
                {isLoading && <Spinner />}
                <video
                    autoPlay
                    loop
                    muted
                    ref={videoRef}
                    id="background-video"
                    playsInline
                    style={{ display: isLoading ? 'none' : 'block' }}
                >
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <CSSTransition
                in={!isLoading}
                timeout={1500}
                classNames="fade"
                unmountOnExit
            >
                <div className="headers">
                    <h1 style={{ color: '#ffffff' }}>Welcome to Let's roll</h1>
                    <h2 style={{ color: '#ffffff' }}>När det är dags att övningsköra</h2>
                    <button
                        className="buttonenter mt-32"
                        style={{ maxWidth: 330 }}
                        onClick={() => navigate(teacherstore.isAuthT ? '/instructorLogin' : '/login')}
                    >
                        Enter
                    </button>
                </div>
            </CSSTransition>
        </div>
    );
};

export default StartPage;