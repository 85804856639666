import React, { useEffect, useState, useCallback } from 'react';
import { getdatausr, svdatausr, svdatausr2 } from '../../services/StudentService';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../../index';
import { toast } from 'react-toastify';
import kommuner from '../RegisterPage/kommuner.json';
import ToggleButton from '../../UI/ToggleButton/Togglebutton';
import { PiUserListLight } from "react-icons/pi";
import Autocomplete from "./Autocomplete";

const MyDetailsPage = () => {
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
        mode: 'onChange',
    });
    const navigate = useNavigate();
    const { store } = useContext(Context);
    const [userData, setUserData] = useState(null);
    const [editableField, setEditableField] = useState(null);
    const [autotransmission, setAutotransmission] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(async () => {
        try {
            const studentId = store.user.id;
            const userDetails = await getdatausr(studentId);
            setUserData(userDetails.data);
            setAutotransmission(userDetails.data.autotransmission);
        } catch (error) {
            toast.error('Error fetching user details');
        } finally {
            setLoading(false);
        }
    }, [store.user.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleEditClick = useCallback((fieldName) => {
        setEditableField(fieldName);
    }, []);

    const handleSaveClick = useCallback(async (fieldName) => {
        try {
            const updatedData = {
                name: fieldName === 'name' ? getValues('name') : userData.name,
                surname: fieldName === 'surname' ? getValues('surname') : userData.surname,
                email: fieldName === 'email' ? getValues('email') : userData.email,
                city: fieldName === 'city' ? getValues('city') : userData.city,
                pnr: fieldName === 'pnr' ? formatPersonnummer(getValues('pnr')) : userData.pnr,
                phone: fieldName === 'phone' ? formatPhoneNumber(getValues('phone')) : userData.phone,
                category: fieldName === 'category' ? getValues('category').split(',').map(item => item.trim()) : userData.category,
            };

            await svdatausr(store.user.id, updatedData);
            toast.success('Uppdaterad!');
            setEditableField(null);
            fetchData();
        } catch (error) {
            toast.error('Ops... något gick fel! Dubbelkolla dina uppgifter');
        }
    }, [fetchData, getValues, store.user.id, userData]);

    const handleSaveClick2 = useCallback(async () => {
        try {
            const updatedData = {
                autotransmission,
            };
            await svdatausr2(store.user.id, updatedData);
            toast.success('Uppdaterad!');
        } catch (error) {
            toast.error('Ops... något gick fel!');
        }
    }, [autotransmission, store.user.id]);

    const formatPersonnummer = (value) => {
        const regex = /^(\d{8})-?(\d{4})$/;
        const match = value.match(regex);
        if (match) {
            return match.slice(1, 3).join('');
        } else {
            throw new Error('Fel format av personnummer');
        }
    };

    const formatPhoneNumber = (value) => {
        const regex = /^\d{10}$/; // Allows 10 digits only
        if (regex.test(value)) {
            return `${value}`; // Add leading 0 before returning
        } else {
            throw new Error('Fel format av tel.nummer');
        }
    };





    const renderEditableField = useCallback((fieldName, fieldType, label) => {
        if (!userData) return null;
        return (
            <>
                {fieldType === 'text' && (
                    <div className='pl-16 pr-16'>
                        <div className='pb-8'>{label}: {userData[fieldName]}</div>
                        <input
                            type="text"
                            {...register(fieldName)}
                            defaultValue={userData[fieldName]}
                        />
                    </div>
                )}

                {fieldType === 'text2' && (
                    <div className='pl-16 pr-16'>
                        <p className='p-8' style={{ color: 'grey', fontStyle: 'italic' }}>10 siffror, exempel: 0701234567</p>

                        <input
                            type="text2"
                            {...register(fieldName)}
                            defaultValue={userData[fieldName]}
                        />
                    </div>
                )}       {fieldType === 'text3' && (
                    <div className='pl-16 pr-16'>
                        <p className='p-8' style={{ color: 'grey', fontStyle: 'italic' }}>12 siffror ex: 19950115-1234</p>

                        <input
                            type="text2"
                            {...register(fieldName)}
                            defaultValue={userData[fieldName]}
                        />
                    </div>
                )}
                {fieldType === 'toggle' && (
                    <div className='pl-16 pr-16'>
                        <div className='pt-16'>{label}: {userData[fieldName] ? 'Ja' : 'Nej'}</div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                {...register(fieldName)}
                                defaultChecked={userData[fieldName]}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                )}
                {fieldType === 'select' && (
                    <div>
                        <div className='pt-16'>{label}: {userData[fieldName]}</div>
                        <select
                            {...register('category')}
                            defaultValue={userData[fieldName]?.join(',')}
                        >
                            <option value="CategoryB">CategoryB</option>
                            <option value="CategoryA">CategoryA</option>
                        </select>
                    </div>
                )}
                {fieldType === 'select2' && (
                    <div>
                        <div className='pt-16'>{label}: {userData[fieldName]}</div>
                        <Autocomplete
                            suggestions={kommuner}
                            onSelect={(selectedCity) => setValue('city', selectedCity)}
                            initialValue={userData[fieldName]}
                        />
                        {errors?.city && <p className='description error'>{errors.city.message}</p>}
                    </div>
                )}
                <button className='button' style={{ maxWidth: 305 }} onClick={() => handleSaveClick(fieldName)}>Spara</button>
            </>
        );
    }, [errors, register, userData, setValue, handleSaveClick]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userData) {
        return <div>Error loading data</div>;
    }

    return (
        <div style={{ maxWidth: "370px", margin: "0 auto" }}>
            <div className="card2 center mt-128 mb-128 ml-8 mr-8">
                <PiUserListLight style={{ width: '40px', height: '40px', color: "#54319f" }} />
                <h2 className=" mt-32 mb-32">Dina uppgifter</h2>

                {editableField === 'name' ? (
                    renderEditableField('name', 'text', 'Namn')
                ) : (
                    <div>
                        <div>Ditt namn</div>
                        <h3 className='pt-16'>{userData.name}</h3>
                        <button className='ghostbutton mt-16' style={{ maxWidth: '150px' }} onClick={() => handleEditClick('name')}>Ändra?</button>
                    </div>
                )}

                {editableField === 'surname' ? (
                    renderEditableField('surname', 'text', 'Efternamn')
                ) : (
                    <div>
                        <div>Ditt efternamn</div>
                        <h3 className='pt-16'>{userData.surname}</h3>
                        <button className='ghostbutton mt-16' style={{ maxWidth: '150px' }} onClick={() => handleEditClick('surname')}>Ändra?</button>
                    </div>
                )}

                {editableField === 'email' ? (
                    renderEditableField('email', 'text', 'E-mail')
                ) : (
                    <div>
                        <div>Din e-mail</div>
                        <h3 className='pt-16'>{userData.email}</h3>
                        <button className='ghostbutton mt-16' style={{ maxWidth: '150px' }} onClick={() => handleEditClick('email')}>Ändra?</button>
                    </div>
                )}

                {editableField === 'pnr' ? (
                    renderEditableField('pnr', 'text3', 'Personnummer')
                ) : (
                    <div>
                        <div className='pb-16'>Personnummer</div>
                        <h3>{userData.pnr}</h3>
                        <button className='ghostbutton mt-16' style={{ maxWidth: '150px' }} onClick={() => handleEditClick('pnr')}>Ändra?</button>
                    </div>
                )}

                {editableField === 'phone' ? (
                    renderEditableField('phone', 'text2', 'Telefonnummer')
                ) : (     <div>
                        <div>Ditt telefonnummer</div>
                        <h3 className='pt-16'>{`0${userData.phone}`}</h3>
                        <button className='ghostbutton mt-16' style={{ maxWidth: '150px' }} onClick={() => handleEditClick('phone')}>Ändra?</button>
                    </div>
                )}

                {editableField === 'category' ? (
                    renderEditableField('category', 'select', 'Kategorin')
                ) : (
                    <div>
                        <div>Körkortskategori</div>
                        <h3 className='pt-16'>{userData.category}</h3>
                        <button className='ghostbutton mt-16' style={{ maxWidth: '150px' }} onClick={() => handleEditClick('category')}>Ändra?</button>
                    </div>
                )}

                {editableField === 'city' ? (
                    renderEditableField('city', 'select2', 'Välj din kommun')
                ) : (
                    <div>
                        <div>Välj din kommun</div>
                        <h3 className='pt-16'>{userData.city}</h3>
                        <button className='ghostbutton mt-16' style={{ maxWidth: '150px' }} onClick={() => handleEditClick('city')}>Ändra?</button>
                    </div>
                )}

                <div className='mb-16 pt-8'>
                    <div className='mb-16 pt-8'>Kör du en automatväxelåda?</div>
                    <ToggleButton
                        type="checkbox"
                        className='mt-16'
                        checked={autotransmission}
                        onChange={() => {
                            setAutotransmission(!autotransmission);
                            handleSaveClick2('autotransmission');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default MyDetailsPage;
