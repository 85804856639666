// import '../../style.css';
// import React, { FC, useContext, useEffect, useState } from 'react';
// import { observer } from "mobx-react-lite";
// import { Context } from "../../index";
// import LoginForm from "./LoginForm";
// import { useNavigate } from "react-router-dom";
// import Spinner from "../../UI/Spinner/Spinner";
// import { checkPremiumStatus } from '../../services/PaymentService';
// import { CSSTransition } from 'react-transition-group';
// import './Login.css';
// import star from './star.gif';
// import hot from './hot.gif';
// import ReactGA from 'react-ga4'; // Import Google Analytics
//
//
// const LoginPage: FC = () => {
//     const { store } = useContext(Context);
//     const navigate = useNavigate();
//     const [isUser, setIsUser] = useState<boolean | null>(null);
//     const [showCookieMessage, setShowCookieMessage] = useState<boolean>(true);
//
//     // cookies
//     useEffect(() => {
//         if (document.cookie.split(';').some((item) => item.trim().startsWith('cookieAccepted='))) {
//             setShowCookieMessage(false);
//         }
//     }, []);
//
//
//
//     // Initialize Google Analytics tracking
//     useEffect(() => {
//         ReactGA.initialize('G-476LSLTQP7'); // Replace with your GA4 Measurement ID
//     }, []);
//
//     // Track pageview when component mounts
//     useEffect(() => {
//         ReactGA.send({ hitType: "pageview", page: window.location.pathname });
//     }, []);
//
//
//     // Handle button clicks with GA tracking
//     const handleHotDealsClick = () => {
//         ReactGA.event({
//             category: 'Button',
//             action: 'Click',
//             label: 'Hot Deals'
//         });
//         navigate('/hotDeals');
//     };
//
//     const handleTrafikverketClick = () => {
//         ReactGA.event({
//             category: 'Button',
//             action: 'Click',
//             label: 'Boka Uppkörning på Trafikverket'
//         });
//         window.open('https://www.trafikverket.se/logga-in/', '_blank');
//     };
//
//     const handleGetPremiumClick = () => {
//         ReactGA.event({
//             category: 'Button',
//             action: 'Click',
//             label: 'Get Premium'
//         });
//         navigate('/levelup');
//     };
//
//
//
//     const handleAcceptCookies = () => {
//         const expires = new Date();
//         expires.setFullYear(expires.getFullYear() + 1);
//         document.cookie = `cookieAccepted=true; expires=${expires.toUTCString()}; path=/`;
//         setShowCookieMessage(false);
//     };
//
//
//
//
//     useEffect(() => {
//         if (store.isAuth) {
//             const checkUser = async () => {
//                 try {
//                     const statusResponse = await checkPremiumStatus(store.user.id);
//                     if (statusResponse.data) {
//                         setIsUser(statusResponse.data.isUser);
//                     }
//                 } catch (error) {
//                     console.error(error);
//                 }
//             };
//             checkUser();
//         }
//     }, [store.isAuth, store.user.id]);
//
//     if (store.isLoading) {
//         return <div><Spinner /></div>;
//     }
//
//     if (!store.isAuth) {
//         return (
//             <div className='jcc center mt-64 mb-32' style={{ maxWidth: 350 }}>
//                 <LoginForm />
//
//             </div>
//         );
//     }
//
//     return (<>
//         <section className='jcc center mt-64' style={{ maxWidth: 350 }}>
//             <h2 className='pb-16'>Välkommen till Let's roll.</h2>
//             <div className='center pt-32'>
//                 {store.isAuth ? (
//                     <>
//                         Inloggad som  <h3 className={'pt-16'}>{store.user.name}</h3>
//                     </>
//                 ) : (
//                     'Please log in'
//                 )}
//             </div>
//
//             {store.user.isActivated ? (
//                 <CSSTransition in={store.user.isActivated} timeout={1500} classNames='fade' unmountOnExit>
//                     <div className='jcc center mt-64 mb-32'>
//                         <button onClick={() => navigate('/notes')} className='buttonstp'>
//                             Mitt övningskörningskort
//                         </button>
//                         <button onClick={() => navigate('/instructor')} className='button'>
//                             Lägg till handledare
//                         </button>
//                         <button onClick={handleHotDealsClick} className='button'>
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <img src={hot} alt='premium' style={{ marginRight: '29px', marginLeft: '29px', width: '40px', marginBottom: -45, marginTop: -50 }} />
//                                 Hot deals
//                             </div>
//                         </button>
//                         <button onClick={handleTrafikverketClick} className='button'>
//                             Boka uppkörning på Trafikverket
//                         </button>
//                         {isUser === false && (
//                             <button onClick={handleGetPremiumClick} className='ghostbuttonP'>
//                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                     <img src={star} alt='premium' style={{ marginRight: '5px', width: '79px', marginBottom: -50, marginTop: -50 }} />
//                                     Get premium
//                                 </div>
//                             </button>
//                         )}
//                         <button onClick={() => navigate('/settings')} className='button mt-64'>
//                             Mina inställningar
//                         </button>
//                         <button className='mb-64 button' onClick={() => store.logout()}>
//                             Log out
//                         </button>
//                     </div>
//                 </CSSTransition>
//             ) : (
//                 <p style={{ fontSize: "1.5rem" }} className={'pt-16'}>Vänligen aktivera ditt konto, aktiveringslänk finns i ditt brevlåda</p>
//             )}
//
//
//         </section>
//             {showCookieMessage && (
//                 <div className='cookie-message'>
//                     <p>We use cookies to improve your experience on our site. By continuing to use our site, you accept our use of cookies.</p>
//                     <button onClick={handleAcceptCookies}>Accept</button>
//                 </div>
//             )}
//         </>
//     );
// };
//
// export default observer(LoginPage);


import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";
import Spinner from "../../UI/Spinner/Spinner";
import { checkPremiumStatus } from '../../services/PaymentService';
import { CSSTransition } from 'react-transition-group';
import './Login.css';
import star from './star.gif';
import hot from './hot.gif';
import ReactGA from 'react-ga4'; // Import Google Analytics

const LoginPage: FC = () => {
    const { store } = useContext(Context);
    const navigate = useNavigate();
    const [isUser, setIsUser] = useState<boolean | null>(null);
    const [showCookieMessage, setShowCookieMessage] = useState<boolean>(true);

    // cookies
    useEffect(() => {
        if (document.cookie.split(';').some((item) => item.trim().startsWith('cookieAccepted='))) {
            setShowCookieMessage(false);
        }
    }, []);

    // Initialize Google Analytics tracking
    useEffect(() => {
        ReactGA.initialize('G-476LSLTQP7'); // Replace with your GA4 Measurement ID
    }, []);

    // Track pageview when component mounts
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    const handleHotDealsClick = () => {
        ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: 'Hot Deals'
        });
        navigate('/hotDeals');
    };

    const handleTrafikverketClick = () => {
        ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: 'Boka Uppkörning på Trafikverket'
        });
        window.open('https://www.trafikverket.se/logga-in/', '_blank');
    };

    const handleGetPremiumClick = () => {
        ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: 'Get Premium'
        });
        navigate('/levelup');
    };

    const handleAcceptCookies = () => {
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        document.cookie = `cookieAccepted=true; expires=${expires.toUTCString()}; path=/`;
        setShowCookieMessage(false);
    };

    useEffect(() => {
        if (store.isAuth) {
            const checkUser = async () => {
                try {
                    const statusResponse = await checkPremiumStatus(store.user.id);
                    if (statusResponse.data) {
                        setIsUser(statusResponse.data.isUser);
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            checkUser();
        }
    }, [store.isAuth, store.user.id]);

    if (store.isLoading) {
        return <div><Spinner /></div>;
    }

    if (!store.isAuth) {
        return (
            <div className='jcc center mt-64 mb-32' style={{ maxWidth: 350 }}>
                <LoginForm />
            </div>
        );
    }

    return (
        <>
            <section className='jcc center mt-64' style={{ maxWidth: 350 }}>
                <h2 className='pb-16'>Välkommen till Let's roll.</h2>
                <div className='center pt-32'>
                    {store.isAuth ? (
                        <>
                            Inloggad som <h3 className={'pt-16'}>{store.user.name}</h3>
                        </>
                    ) : (
                        'Please log in'
                    )}
                </div>

                <CSSTransition
                    in={store.user.isActivated}
                    timeout={1700}
                    classNames='fade'
                    unmountOnExit
                >
                    <div className='jcc center mt-64 mb-32'>
                        <button onClick={() => navigate('/notes')} className='buttonstp'>
                            Mitt övningskörningskort
                        </button>
                        <button onClick={() => navigate('/instructor')} className='button'>
                            Lägg till handledare
                        </button>
                        <button onClick={handleHotDealsClick} className='button'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={hot} alt='premium' style={{ marginRight: '29px', marginLeft: '29px', width: '40px', marginBottom: -45, marginTop: -50 }} />
                                Hot deals
                            </div>
                        </button>
                        <button onClick={handleTrafikverketClick} className='button'>
                            Boka uppkörning på Trafikverket
                        </button>
                        {isUser === false && (
                            <button onClick={handleGetPremiumClick} className='ghostbuttonP'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={star} alt='premium' style={{ marginRight: '5px', width: '79px', marginBottom: -50, marginTop: -50 }} />
                                    Get premium
                                </div>
                            </button>
                        )}
                        <button onClick={() => navigate('/settings')} className='button mt-64'>
                            Mina inställningar
                        </button>
                        <button className='mb-64 button' onClick={() => store.logout()}>
                            Log out
                        </button>
                    </div>
                </CSSTransition>

                {!store.user.isActivated && (
                    <p style={{ fontSize: "1.5rem" }} className={'pt-16'}>
                        Vänligen aktivera ditt konto, aktiveringslänk finns i ditt brevlåda
                    </p>
                )}
            </section>
            {showCookieMessage && (
                <div className='cookie-message'>
                    <p>We use cookies to improve your experience on our site. By continuing to use our site, you accept our use of cookies.</p>
                    <button onClick={handleAcceptCookies}>Accept</button>
                </div>
            )}
        </>
    );
};

export default observer(LoginPage);
